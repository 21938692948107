import React, { useState } from 'react';


export const NumberSlider = ({ value, onChange, min, max, stepSlider = 1, stepNumber = 0.1, absMax, ...rest }) => {
    var [internal, setInternal] = useState(0);

    if (value != undefined) {
        internal = value;
        setInternal = () => { };
    }

    const onInput = (e) => {
        let value = e.target.value;
        if (typeof absMax != undefined) {
            if (value > absMax) {
                return;
            }
        }

        setInternal(value);
        onChange(e);
    }

    return (
        <div className="slider-container" {...rest}>
            <input type="range" name="amountRange" className="slider" min={min} max={max} step={stepSlider} value={internal} onInput={onInput} />
            <input type="number" name="amountInput" className="slider-number" min={min} max={max} step={stepNumber} value={internal} onInput={onInput} />
        </div>
    );
}


export const LabeledSlider = ({ label, ...rest }) => {
    return (
        <div className='labeled-slider'>
            <div className="slider-label">{label}</div>
            <div className="slider-slider">
                <NumberSlider {...rest} />
            </div>
        </div>
    );
}

export default NumberSlider;