import { ethers, utils } from "ethers";
import { json, number } from "mathjs";

import * as utils2 from "./utils2";

import StrangeAttractors from "../../artifacts/contracts/StrangeAttractors.sol/StrangeAttractors.json";
import IAttractorSolver from "../../artifacts/contracts/solvers/IAttractorSolver.sol/IAttractorSolver.json";

// const strangeAddress = "0xA67999ef432a1b140D6666695F8cbE11C45247dF"
// const strangeAddress = "0x0618F92065Ca026C07a54439c69913a7582ce05E"

// mainnet
const strangeAddress = "0x1cA15CCdd91b55CD617a48dC9eEFb98CAe224757";

export const requestAccount = () => {
  return window.ethereum.request({ method: "eth_requestAccounts" });
};

export const getContract = () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const contract = new ethers.Contract(
    strangeAddress,
    StrangeAttractors.abi,
    provider
  );
  return contract;
};

export const getAlchemyProvider = () => {
  const alchemy = new ethers.providers.AlchemyProvider(
    "homestead",
    "R0ELiPYvXai6QxeFrrL55ql2tJc6UcLR"
  );
  return alchemy;
};

export const getInfuraProvider = () => {
  const infura = new ethers.providers.InfuraProvider(
    "homestead",
    "90dc4dbf4d5e4dd8a304731ee2dd6f99"
  );
  return infura;
};

export const getContractAlchemy = (
  address = strangeAddress,
  abi = StrangeAttractors.abi
) => {
  const provider = getAlchemyProvider();
  const contract = new ethers.Contract(address, abi, provider);
  return contract;
};

export const getSignedContract = () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(
    strangeAddress,
    StrangeAttractors.abi,
    signer
  );
  return contract;
};

export const parseProjectionParameters = (projectionParameters) => {
  let params = {
    axis1: projectionParameters.axis1.map((x) => utils2.fxp2flt(x)),
    axis2: projectionParameters.axis2.map((x) => utils2.fxp2flt(x)),
    offset: projectionParameters.offset.map((x) => utils2.fxp2flt(x)),
  };
  return params;
};

export const convertProjectionParameters = (projectionParameters) => {
  console.log("Converting projection config");
  return {
    axis1: projectionParameters.axis1.map((x) => utils2.flt2fxp(x)),
    axis2: projectionParameters.axis2.map((x) => utils2.flt2fxp(x)),
    offset: projectionParameters.offset.map((x) => utils2.flt2fxp(x)),
  };
};

const hexToRgb = (hex) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
};

const encodeAnchor = ({ color, position }) => {
  const [r, g, b] = hexToRgb(color);
  return ((((((((r << 8) >>> 0) + g) << 8) >>> 0) + b) << 8) >>> 0) + position;
};

const encodeAnchors = (anchors) => {
  return [...anchors]
    .sort((first, second) => first.position - second.position)
    .map((anchor) => encodeAnchor(anchor));
};

export const getToken = async (tokenId) => {
  try {
    const contract = await getContract();
    return await contract.tokens(tokenId);
  } catch (error) {
    console.log(error);
  }
};

export const getTokenUri = async (tokenId) => {
  try {
    const contract = await getContractAlchemy();
    const tokenURI = await contract.tokenURI(tokenId);
    return tokenURI;
  } catch (error) {
    console.log(error);
  }
};

export const renderTokenId = async (tokenId) => {
  try {
    let tokenUri = await getTokenUri(tokenId);
    let json = JSON.parse(tokenUri.substr(22));
    return json.image.substr(19);
  } catch (error) {
    console.log(error);
  }
};

export const renderTokenIdDefault = async (tokenId) => {
  try {
    let systemId = utils2.getTokenSystemId(tokenId);
    let edition = utils2.getTokenEdition(tokenId);

    const contract = await getContractAlchemy();
    const system = await contract.systems(systemId);
    let colors = await system.defaultColorAnchors;
    let size = await system.defaultRenderSize;
    let solverAddress = await system.solver;

    const solver = await getContractAlchemy(
      solverAddress,
      IAttractorSolver.abi
    );
    let proj = await solver.getDefaultProjectionParameters(edition);

    return await contract.renderWithConfig(tokenId, proj, colors, size);
  } catch (error) {
    console.log(error);
  }
};

export const renderToken = async (
  tokenId,
  projectionParameters,
  colorAnchors,
  renderSize
) => {
  try {
    const contract = await getContract();
    console.log(
      "Rendering token",
      tokenId,
      "with config",
      projectionParameters,
      colorAnchors
    );

    let encodedAnchors = encodeAnchors(colorAnchors);
    const figure = await contract.renderWithConfig(
      tokenId,
      projectionParameters,
      encodedAnchors,
      renderSize
    );
    return figure;
  } catch (error) {
    console.log(error);
  }
};

export const fetchTokenList = async (account) => {
  console.log("Fetching tokens of ", account);
  try {
    const contract = getContract();

    let numberOfTokens = await contract.balanceOf(account);
    console.log("num tokens", numberOfTokens);

    let tokens = [];

    const getToken = async (idx) => {
      let tokenId = await contract.tokenOfOwnerByIndex(account, idx);

      let tokenName = unescape(await contract.getTokenName(tokenId));
      tokens.push({ label: tokenName, value: tokenId });
    };

    const txs = [];
    for (let idx = 0; idx < numberOfTokens; ++idx) {
      txs.push(getToken(idx));
    }
    await Promise.all(txs);

    tokens = tokens.sort((left, right) => left.value - right.value);

    console.log("Fetched tokens:", tokens);

    return tokens;
  } catch (error) {
    console.error(error);
  }
};

// export const fetchToken = async (tokenId) => {
//     try {
//         const provider = new ethers.providers.Web3Provider(window.ethereum);
//         const contract = new ethers.Contract(strangeAddress, StrangeAttractors.abi, provider);
//         let token = await contract.tokens(tokenId);

//         console.log('Fetched token:', token);

//         return token;
//     } catch (error) {
//         console.log(error);
//     }
// }

export const fetchTokenProjectionParameters = async (tokenId) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      strangeAddress,
      StrangeAttractors.abi,
      provider
    );
    return await contract.getProjectionParameters(tokenId);
  } catch (error) {
    console.log(error);
  }
};

// export const fetchTokenColormap = async (tokenId) => {
//     try {
//         const provider = new ethers.providers.Web3Provider(window.ethereum);
//         const contract = new ethers.Contract(strangeAddress, StrangeAttractors.abi, provider);
//         return await contract.getColormap(tokenId);
//     } catch (error) {
//         console.log(error);
//     }
// }

const rgbToHex = ([r, g, b]) => {
  function componentToHex(c) {
    let hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

const decodeAnchor = (anchor) => {
  let r = (anchor >> 24) & 0xff;
  let g = (anchor >> 16) & 0xff;
  let b = (anchor >> 8) & 0xff;

  let _anchor = {
    position: anchor & 0xff,
    color: rgbToHex([r, g, b]),
  };

  return _anchor;
};

export const fetchTokenColorAnchors = async (tokenId) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      strangeAddress,
      StrangeAttractors.abi,
      provider
    );
    let anchors = await contract.getColorAnchors(tokenId);

    anchors = anchors.map((anchor, idx) => {
      return {
        ...decodeAnchor(anchor),
        id: idx,
        lastUpdate: 0,
      };
    });
    return anchors;
  } catch (error) {
    console.log(error);
  }
};

export const uploadProjectionParameters = async (
  tokenId,
  projectionParameters
) => {
  try {
    if (typeof window.ethereum !== "undefined") {
      const contract = await getSignedContract();
      let convertedParams = convertProjectionParameters(projectionParameters);
      await contract.setProjectionParameters(tokenId, convertedParams);
    }
  } catch (e) {
    console.log(e);
  }
};

// export const uploadColormap = async (tokenId, colormap) => {
//     try {
//         if (typeof window.ethereum !== 'undefined') {
//             const contract = await getSignedContract();
//             await contract.setColormap(tokenId, colormap);
//         }
//     } catch (e) {
//         console.log(e)
//     }
// }

export const uploadColorAnchors = async (tokenId, colorAnchors) => {
  try {
    if (typeof window.ethereum !== "undefined") {
      const contract = await getSignedContract();

      let encodedAnchors = encodeAnchors(colorAnchors);
      await contract.setColorAnchors(tokenId, encodedAnchors);
    }
  } catch (e) {
    console.log(e);
  }
};

export const fetchDefaultColormap = async (systemId) => {
  try {
    if (typeof window.ethereum !== "undefined") {
      const contract = await getContract();
      const system = await contract.systems(systemId);
      return await system.defaultColormap;
    }
  } catch (e) {
    console.log(e);
  }
};

export const mint = async (slot) => {
  console.log(slot);
  try {
    if (typeof window.ethereum !== "undefined") {
      const contract = getSignedContract();

      const MINT_PRICE = await contract.MINT_PRICE();
      await contract.safeMintRegularToken(slot.nonce, slot.signature, {
        value: MINT_PRICE,
        gasLimit: 300000,
      });
    }
  } catch (err) {
    console.log("Mint Error: ", err);
  }
};

export const mintFullset = async ([token1, token2, token3, token4]) => {
  try {
    console.log([token1, token2, token3, token4]);
    if (typeof window.ethereum !== "undefined") {
      const contract = getSignedContract();
      await contract.safeMintFullsetToken([token1, token2, token3, token4]);
    }
  } catch (err) {
    alert(err);
    console.error("Mint Error: ", err);
  }
};

async function filter(arr, callback) {
  const fail = Symbol();
  return (
    await Promise.all(
      arr.map(async (item) => ((await callback(item)) ? item : fail))
    )
  ).filter((i) => i !== fail);
}

export const getValidSlots = async (grantee, allSlots) => {
  try {
    let contract = getContract();
    let userSlots = allSlots.filter((element) => {
      return element.grantee.toLowerCase() == grantee.toLowerCase();
    });
    let slots = await filter(userSlots, async (slot) => {
      return await contract.isValidSlot(grantee, slot.nonce, slot.signature);
    });
    return slots;
  } catch (err) {
    console.error(err);
  }
};

export const getAllValidSlots = async (allSlots) => {
  try {
    let contract = getContract();
    let slots = await filter(allSlots, async (slot) => {
      return await contract.isValidSlot(
        slot.grantee,
        slot.nonce,
        slot.signature
      );
    });
    return slots;
  } catch (err) {
    console.error(err);
  }
};

export const resetRenderParameters = async (
  tokenId,
  { resetProjection, resetColors, resetSize }
) => {
  try {
    let contract = getSignedContract();
    await contract.resetRenderParameters(
      tokenId,
      resetProjection,
      resetColors,
      resetSize
    );
  } catch (err) {
    console.error(err);
  }
};

export const uploadRenderSize = async (tokenId, renderSize) => {
  try {
    let contract = getSignedContract();
    await contract.setRenderSize(tokenId, renderSize);
  } catch (err) {
    console.error(err);
  }
};
