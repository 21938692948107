import { ethers } from 'ethers'
const saveSvgAsPng = require('save-svg-as-png')

const downloadBlob = (blob, filename) => {
    const objectUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = objectUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
}

export const downloadSVG = (data, filename='strange-attractor.svg') => {
    const blob = new Blob([data], { type: "image/svg+xml" });
    downloadBlob(blob, filename);
};


export const downloadPNG = (svg, filename) => {
    const imageOptions = {
        scale: 0.25,
        encoderOptions: 1,
        left: -4096,
        top: -4096,
        width: 8192,
        height: 8192,
    }

    const container = document.createElement("div");
    container.innerHTML = svg;
    container.setAttribute('id', 'svg-container');
    document.body.appendChild(container);
    saveSvgAsPng.saveSvgAsPng(document.getElementById('svg-container').firstChild, filename, imageOptions);
    document.body.removeChild(container);
};

export const downloadJSON = (data, filename) => {
    const blob = new Blob([data], { type: "application/json" });
    downloadBlob(blob, filename);
};

const PRECISION = 96;

export function flt2fxp(float) {
    return ethers.BigNumber.from(BigInt(Math.floor(float * 2 ** PRECISION)));
}

export function fxp2flt(myInt) {
    return parseFloat(myInt.toBigInt()) / 2 ** PRECISION;
}

export const parseProjectionParameters = (projectionParameters) => {
    let params = {
        axis1: projectionParameters.axis1.map(x => fxp2flt(x)),
        axis2: projectionParameters.axis2.map(x => fxp2flt(x)),
        offset: projectionParameters.offset.map(x => fxp2flt(x)),
    };
    return params;
}

export const convertProjectionParameters = (projectionParameters) => {
    console.log('Converting projection config')
    return {
        axis1: projectionParameters.axis1.map(x => flt2fxp(x)),
        axis2: projectionParameters.axis2.map(x => flt2fxp(x)),
        offset: projectionParameters.offset.map(x => flt2fxp(x))
    };
}

export const getTokenSystemId = (tokenId) => {
    let systemId = Math.floor(parseInt(tokenId) / 1000);
    return systemId;
}


export const getTokenEdition = (tokenId) => {
    return tokenId % 1000;
}

export const isValidTokenId = (tokenId) => {
    return (
        (tokenId >= 0) && (tokenId < 128)
    ) || (
            (tokenId >= 1000) && (tokenId < 1128)
        ) || (
            (tokenId >= 2000) && (tokenId < 2128)
        ) || (
            (tokenId >= 3000) && (tokenId < 3128)
        ) || (
            (tokenId >= 4000) && (tokenId < 4128)
        )
}

const systemNames = ["Huang", "Lorenz", "Dadras-Momeni", "Halvorsen", "Coullet"];

export const getTokenName = (tokenId) => {
    return `${systemNames[getTokenSystemId(tokenId)]}-${getTokenEdition(tokenId).toString().padStart(3, '0')}`;
}


export const getTokenNameHash = (tokenId) => {
    return `${systemNames[getTokenSystemId(tokenId)]} #${getTokenEdition(tokenId).toString()}`;
}

export const rgbToHex = ({ red, green, blue }) => {
    function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }
    return componentToHex(red) + componentToHex(green) + componentToHex(blue);
}

export const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16)
    } : null;
}

const interpolateRGB = (rgb1, rgb2, fraction) => {
    return {
        red: Math.floor(rgb1.red + fraction * (rgb2.red - rgb1.red)),
        green: Math.floor(rgb1.green + fraction * (rgb2.green - rgb1.green)),
        blue: Math.floor(rgb1.blue + fraction * (rgb2.blue - rgb1.blue)),
    }
};

const pairwise = (arr, func) => {
    for (var i = 0; i < arr.length - 1; i++) {
        func(arr[i], arr[i + 1])
    }
}

export const computeColormap = (anchors) => {
    var colors = new Array(256);

    // Extrapolating colors below the leftmost anchor
    for (let idx = 0; idx <= anchors[0].position; idx++) {
        colors[idx] = rgbToHex(anchors[0]);
    }

    if (anchors.length >= 2) {

        // Interpolating colors between anchors
        pairwise(anchors, (left, right) => {
            for (let idx = left.position; idx < right.position; idx++) {
                let factor = (idx - left.position) / (right.position - left.position);
                colors[idx] = rgbToHex(interpolateRGB(left, right, factor));
            }
        })
    }

    // Extrapolating colors above the rightmost anchor
    for (let idx = anchors[anchors.length - 1].position; idx < 256; idx++) {
        colors[idx] = rgbToHex(anchors[anchors.length - 1]);
    }

    return '0x' + colors.join('');
}
