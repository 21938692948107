import './Admin.css';
import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers'

import * as utils from '../../components/MyTools/utils.js';
import { ConnectButton } from '../../components/MyTools/ConnectButton';

import slotsEarly from '../Mint/slots.json';


const Admin = () => {
  const [account, setAccount] = useState("")
  const [isOwner, setIsOwner] = useState(false)

  const checkIsOwner = async () => {
    try {
      const contract = utils.getContract();
      let owner = await contract.owner();
      setIsOwner(owner.toUpperCase() === account.toUpperCase());
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(checkIsOwner, [account])


  const [isSlotRestricted, setIsSlotRestricted] = useState(true);
  const [isFullsetMintEnabled, setIsFullsetMintEnabled] = useState(true);
  const [totalSupply, setTotalSupply] = useState(0);

  const [remaining, setRemaining] = useState([0, 0, 0, 0])

  const setup = async () => {
    try {
      const contract = utils.getContract();
      let isSlotRestricted = await contract.isSlotRestricted();
      setIsSlotRestricted(isSlotRestricted);


      let isFullsetMintEnabled = await contract.isFullsetMintEnabled();
      setIsFullsetMintEnabled(isFullsetMintEnabled);

      setTotalSupply((await contract.totalSupply()).toNumber());

      let remaining = []
      for (let idx = 1; idx < 5; idx++) {
        remaining.push((await contract.systems(idx)).numLeftForMint)
      }
      setRemaining(remaining);
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(setup, []);


  const enableRestriction = async () => {
    try {
      const contract = utils.getSignedContract();
      await contract.setSlotRestriction(true);
    } catch (error) {
      console.log(error);
    }
  }


  const disableRestriction = async () => {
    try {
      const contract = utils.getSignedContract();
      await contract.setSlotRestriction(false);
    } catch (error) {
      console.log(error);
    }
  }


  const disableFullset = async () => {
    try {
      const contract = utils.getSignedContract();
      await contract.setSlotRestriction(true);
    } catch (error) {
      console.log(error);
    }
  }


  const enableFullset = async () => {
    try {
      const contract = utils.getSignedContract();
      await contract.enableFullsetMint(true);
    } catch (error) {
      console.log(error);
    }
  }


  const [validSlotsEarly, setValidSlotsEarly] = useState([])

  const countValidSlots = async () => {
    setValidSlotsEarly('??');
    let validSlots = await utils.getAllValidSlots(slotsEarly);
    console.log(validSlots);
    setValidSlotsEarly(validSlots);
  }

  return (

    <div className="admin">
      <div>
        <ConnectButton {...{ account, setAccount }} />
      </div>

      <div>Remaining Pieces: {remaining.join(' | ')}</div>
      <div>Next numbers: {remaining.map(x => 128 - x).join(' | ')}</div>

      {isOwner &&
        <div>
          <div>
            <div>Total supply: {totalSupply}</div>
            <div>Slot sestriction: {isSlotRestricted.toString()}</div>
            <div>Finale: {isFullsetMintEnabled.toString()}</div>

          </div>

          <div>
            <input type="button" onClick={enableRestriction} value="Enable Restriction" />
            <input type="button" onClick={disableRestriction} value="Disable Restriction" />
          </div>

          <div>
            <input type="button" onClick={enableFullset} value="Enable Finale" />
            <input type="button" onClick={disableFullset} value="Disable Finale" />
          </div>

          <div>
            <span>Valid Slots Early: {validSlotsEarly.length}</span>
            <input type="button" onClick={countValidSlots} value="Count" />
          </div>

        </div>
      }

    </div>
  );
}

export default Admin;
