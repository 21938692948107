import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { white } from '@mui/material/colors';

let theme = createTheme({
    palette: {
        mode: 'dark'
    },
    typography: {
        fontFamily: [
            'DMMono',
        ].join(','),
        fontSize: 10,
    },
});

// theme = responsiveFontSizes(theme);

export default theme;