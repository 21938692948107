import { LabeledSlider } from '../MyTools/NumberSlider.js';
import React, { useEffect, useState } from 'react';
import { norm, divide, cross, dot, subtract, inv, matrix, multiply, transpose } from 'mathjs';

export const ProjectionSliders = ({ projectionParameters, setProjectionParameters }) => {
    const [selfUpdate, setSelfUpdate] = useState(false);

    const [azi, setAzi] = useState(0);
    const [alt, setAlt] = useState(0);
    const [rot, setRot] = useState(0);
    const [angle, setAngle] = useState(90);
    const [scale1, setScale1] = useState(2);
    const [scale2, setScale2] = useState(2);

    const deg = Math.PI / 180;

    const cos = (x) => Math.cos(x * deg);
    const sin = (x) => Math.sin(x * deg);
    const round = (x, digits) => Number(x.toFixed(digits));
    const clip1 = (x) => Math.max(-1, Math.min(1, x));

    const computeAxis1 = ({ azi, alt, rot, scale1 }) => {
        let dir = [
            cos(rot) * cos(azi) - sin(alt) * sin(rot) * sin(azi),
            cos(rot) * sin(azi) + cos(azi) * sin(alt) * sin(rot),
            cos(alt) * sin(rot)
        ];
        return dir.map(x => x * scale1);
    }

    const computeAxis2 = ({ azi, alt, rot, angle, scale2 }) => {
        let dir = [
            cos(rot + angle) * cos(azi) - sin(alt) * sin(rot + angle) * sin(azi),
            cos(rot + angle) * sin(azi) + cos(azi) * sin(alt) * sin(rot + angle),
            cos(alt) * sin(rot + angle)
        ];
        return dir.map(x => x * scale2);
    }

    const updateProjectionParameters = (allAngles) => {
        setSelfUpdate(true);
        setProjectionParameters({
            ...projectionParameters,
            axis1: computeAxis1(allAngles),
            axis2: computeAxis2(allAngles)
        });
    }

    const updateAngles = () => {
        console.log('updating angles form config')
        let a1 = projectionParameters.axis1;
        let a2 = projectionParameters.axis2;

        let l1 = norm(a1);
        let l2 = norm(a2);

        a1 = divide(a1, l1);
        a2 = divide(a2, l2);

        let alpha = Math.acos(clip1(dot(a1, a2)));

        let dir = cross(a1, a2);
        dir = divide(dir, norm(dir));

        let alt = Math.asin(clip1(dir[2]));
        let azi;
        let rot;

        // For the polar caps (alt = +- 90 deg), azi and rot are degenerate.
        // Solve this by setting rot to zero
        if (Math.abs(Math.abs(alt) - Math.PI / 2) < 0.001) {
            azi = Math.acos(clip1(a1[0]));
            rot = 0;
        } else {
            azi = (Math.atan2(dir[0], -dir[1]) + 2 * Math.PI) % (2 * Math.PI);
            let tmp = a1[0] * Math.cos(azi) + a1[1] * Math.sin(azi);
            rot = Math.acos(clip1(tmp));
        }

        azi = (azi / deg + 360) % 360
        alt = (alt / deg + 90) % 180 - 90
        rot = (rot / deg + 360) % 360
        alpha = (alpha / deg + 360) % 360

        setAzi(round(azi, 1));
        setAlt(round(alt, 1));
        setRot(round(rot, 1));
        setAngle(round(alpha, 1));
        setScale1(round(l1, 2));
        setScale2(round(l2, 2));
    }

    useEffect(() => {
        if (!selfUpdate) {
            updateAngles();
        }
        setSelfUpdate(false);
    },
        [projectionParameters]
    )


    const handleAziChange = (e) => {
        let value = Number(e.target.value);
        setAzi(value);
        console.log('Azi change', value)
        updateProjectionParameters({ azi: value, alt, rot, angle, scale1, scale2 });
    }

    const handleAltChange = (e) => {
        let value = Number(e.target.value);
        setAlt(value);
        updateProjectionParameters({ azi, alt: value, rot, angle, scale1, scale2 });
    }

    const handleRotChange = (e) => {
        let value = Number(e.target.value);
        setRot(value);
        updateProjectionParameters({ azi, alt, rot: value, angle, scale1, scale2 });
    }

    const handleAngleChange = (e) => {
        let value = Number(e.target.value);
        setAngle(value);
        updateProjectionParameters({ azi, alt, rot, angle: value, scale1, scale2 });
    }

    const handleScale1Change = (e) => {
        let value = Number(e.target.value);
        setScale1(value);
        updateProjectionParameters({ azi, alt, rot, angle, scale1: value, scale2 });
    }

    const handleScale2Change = (e) => {
        let value = Number(e.target.value);
        setScale2(value);
        updateProjectionParameters({ azi, alt, rot, angle, scale1, scale2: value });
    }

    return (
        <div className="projectionSliders">
            <LabeledSlider label="Azimuth" min={0} max={360} value={azi} onChange={handleAziChange} />
            <LabeledSlider label="Altitude" min={-90} max={90} value={alt} onChange={handleAltChange} />
            <LabeledSlider label="Rotation" min={0} max={360} value={rot} onChange={handleRotChange} />
            <LabeledSlider label="Angle" min={0} max={360} value={angle} onChange={handleAngleChange} />
            <LabeledSlider label="Scale 1" min={0} max={50} absMax={200} stepSlider={0.1} value={scale1} onChange={handleScale1Change} />
            <LabeledSlider label="Scale 2" min={0} max={50} absMax={200} stepSlider={0.1} value={scale2} onChange={handleScale2Change} />
        </div>
    );
}

export default ProjectionSliders;