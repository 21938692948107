import './Details.css';
import React, { useState } from 'react';
import { Helmet } from "react-helmet";

import { Stack } from '@mui/material';
import { MathJaxContext, MathJax } from 'better-react-mathjax';

import lorenz from '../../assets/lorenzXZ.svg';
import dadras from '../../assets/dadras.svg';
import halvorsen from '../../assets/halvorsen.svg';
import coullet from '../../assets/coullet.svg';
import huang from '../../assets/huang.svg';

import lorenzProjection1a from '../../assets/lorenzProjection1a.svg';
import lorenzProjection1b from '../../assets/lorenzProjection1b.svg';
import lorenzProjection2b from '../../assets/lorenzProjection2b.svg';
import lorenzProjection2c from '../../assets/lorenzProjectedSol_pi3_-pi3.svg';

const Math = (props) => {
  const mathJaxConfig = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [["$", "$"]],
      displayMath: [["$$", "$$"]]
    }
  };

  return (
    <MathJaxContext config={mathJaxConfig} version={3}>
      <MathJax inline>
        {props.expr}
      </MathJax>
    </MathJaxContext>
  );
}


const About = () => {

  return (
    <div className="details">
      <Helmet>
        <title>Technical Details | Strange Attractors</title>
        <meta name="description" content="A peek under the hood of Strange Attractors -- an interactive, on-chain, generative art, NFT project that simulates three-dimensional, chaotic systems using nothing but an ethereum smart contract." />
      </Helmet>
      <div className="textblock">

        <h2>A short introduction</h2>

        <h4>What are attractors?</h4>
        <p>
          In dynamical systems, an <a href="https://en.wikipedia.org/wiki/Attractor">attractor</a> is a set of states towards which a system tends to evolve for a wide variety of starting conditions.
          Consider the pendulum as a simple example for such a dynamic system. Once set in motion, it will gradually lose energy due to friction and eventually reach the resting position again. In other words, the pendulum's state is pulled towards its resting position - the attractor of the system.
        </p>

        <p>
          Strange attractors set themselves apart by having fractal structure and often emerge from chaotic systems, which makes them especially interesting to visualize.
          Chaotic systems are very sensitive to their initial conditions, meaning that two arbitrarily close points can become arbitrarily far apart as the system evolves - a phenomenon also known as the butterfly effect.
          A dynamic system with a chaotic attractor is therefore locally unstable yet globally stable: once the system has entered the attractor, nearby states diverge from one another but never depart from the attractor.
        </p>


        {/* <p className="sectionTitle">What's new?</p>
        <p>
          The non-linear coupling in chaotic dynamical systems makes it impossible to solve their evolution analytically.
          Instead, numerical methods have to be employed to simulate and study their behaviour.
          While this has already been done in the past, this project is the first to realise the solution of multi-dimensional differential equations and their visualisation in-chain using nothing but solidity.
          There are no external dependencies other than a browser to view the resulting SVG graphic.
          With this, I am trying to push the limits of what is currently possible with smart-contracts and demonstrate that Solidity is able to handle complex numerical algorithms that are usually implemented using other languages.
          <br />
          As an example, the above picture shows a chaotic Lorenz attractor computed entirely by my smart-contract.
        </p> */}


        <h4>A prominent example: The Lorenz Attractor</h4>
        <p>
          One of the most famous strange attractors is the <a href="https://en.wikipedia.org/wiki/Lorenz_system">Lorenz attractor</a>, a three-dimensional structure that projected resembles a butterfly or a mask (shown below).
          It is named after Edward Lorenz, an American mathematician who discovered the attractor while developing and studying a mathematical model to describe the Earth's atmosphere.
        </p>

        <div className="lorenz-example">
          <img src={lorenz} className="border" />
        </div>

        <p>
          The model can be understood in simple terms by imagining an upright rectangular slice through our atmosphere that is heated by the Earth from below and cooled from above by the void of outer space.
          Within this slice, air can move freely driven by thermal convection, where warm air rises and cold air sinks.
          In this model, the average state of the atmosphere can be described in three dimensions by:
          <ul>
            <li><Math expr={"$x$"} />, the rate of convection</li>
            <li><Math expr={"$y$"} />, the horizontal temperature variation</li>
            <li><Math expr={"$z$"} />, the vertical temperature variation</li>
          </ul>
          The evolution of these quantities is governed by a coupled set of ordinary differential equations, the so called Lorenz system:
          {/* <Math expr={lorenzSystem} /> */}
          <div className="lorenz-system">
            <Math expr={String.raw`$\dot{x} = \sigma (y  - x)$`} />
            <Math expr={String.raw`$\dot{y} =  x (\rho - z) - y$`} />
            <Math expr={String.raw`$\dot{z} = xy - \beta z$`} />
          </div>
          The symbols <Math expr={String.raw`$\beta, \rho, \sigma$`} /> correspond to the free parameters of the system, describing important properties of the atmospheric slice:
          <ul>
            <li><Math expr={String.raw`$\rho$`} />, the temperature difference between the top and the bottom of slice</li>
            <li><Math expr={String.raw`$\sigma$`} />, the ratio of viscosity to thermal conductivity </li>
            <li><Math expr={String.raw`$\beta$`} />, the width-to-height ratio of the slice</li>
          </ul>

          The shown Lorenz attractor was computed using the classical parameters <Math expr={String.raw`$\rho = 28$`} />, <Math expr={String.raw`$\sigma = 10$`} />, <Math expr={String.raw`$\beta = 8/3$`} />, and <Math expr={'$x=y=z=10$'} /> as the starting point.
          For the visualisation, the solution was projected to the <Math expr={'$x-z$'} /> plane.
        </p>

        <h4 id='systems'>Systems</h4>
        <p>
          The collection is composed of 5 systems in total.
          Next to the Lorenz system described above (
          <span className="address"><a href="https://etherscan.io/address/0xCdf37972Dd8a3Ff2D1C11c30DCFBc25A2506583B" target="blank">0xCdf379...583B</a></span>), they are described by the following differential equations.
        </p>

        <div className="systems-grid">
          <div>
            <h4>Dadras-Momeni</h4>
            <img src={dadras} />
            <div className="address">
              <a href="https://etherscan.io/address/0x5FcaBE7BC1455a9C15BBF85834f53af1aB8B2544" target="blank">0x5FcaBE...2544</a>
            </div>
            <div className="system-equations">
              <div className="eq-container-inner">
                <Math expr={String.raw`$\dot{x} = \beta y^2 z - \alpha x y $`} />
                <Math expr={String.raw`$\dot{y} =  \delta y + (1 - x) z $`} />
                <Math expr={String.raw`$\dot{z} = \delta x y - \epsilon z$`} />
              </div>
              <div className="eq-container-inner">
                <Math expr={String.raw`$\alpha = 3 $`} />
                <Math expr={String.raw`$\beta =  2.7 $`} />
                <Math expr={String.raw`$\gamma = 1.7 $`} />
                <Math expr={String.raw`$\delta  =  2 $`} />
                <Math expr={String.raw`$\epsilon  =  2.7 $`} />
              </div>
            </div>
          </div>


          <div>
            <h4>Halvorsen</h4>
            <img src={halvorsen} />
            <div className="address">
              <a href="https://etherscan.io/address/0x548333E234326CB1efee9BA9ce74Ead3B08380fA" target="blank">0x548333...80fA</a>
            </div>
            <div className="system-equations">
              <div className="eq-container-inner">
                <Math expr={String.raw`$\dot{x} = \alpha x - 4 y - 4 z - y^2 $`} />
                <Math expr={String.raw`$\dot{y} =  \alpha y - 4 z - 4 x - z^2 $`} />
                <Math expr={String.raw`$\dot{z} = \alpha z - 4 x - 4 y - x^2$`} />
              </div>
              <div className="eq-container-inner">
                <Math expr={String.raw`$\alpha = 1.4 $`} />
              </div>
            </div>
          </div>

          <div>
            <h4>Coullet</h4>
            <img src={coullet} />
            <div className="address">
              <a href="https://etherscan.io/address/0x8509d347caa93e7db87e0287ee51867bfcb7b9d2" target="blank">0x8509d3...b9D2</a>
            </div>
            <div className="system-equations">
              <div className="eq-container-inner">
                <Math expr={String.raw`$\dot{x} = y $`} />
                <Math expr={String.raw`$\dot{y} = z $`} />
                <Math expr={String.raw`$\dot{z} = \alpha x + \beta y + \gamma z + \delta x^3 $`} />
              </div>
              <div className="eq-container-inner">
                <Math expr={String.raw`$\alpha = 0.8 $`} />
                <Math expr={String.raw`$\beta =  -1.1 $`} />
                <Math expr={String.raw`$\gamma = -0.45 $`} />
                <Math expr={String.raw`$\delta  =  -0.01 $`} />
              </div>
            </div>
          </div>


          <div>
            <h4>Huang</h4>
            <img src={huang} />
            <div className="address">
              <a href="https://etherscan.io/address/0xd15E042BDDDBE32d9AF9484EeC5B3c40f2332438" target="blank">0xd15E04...2438</a>
            </div>
            <div className="system-equations">
              <div className="eq-container-inner">
                <Math expr={String.raw`$\dot{x} = \alpha (y - x) $`} />
                <Math expr={String.raw`$\dot{y} = w + x z $`} />
                <Math expr={String.raw`$\dot{z} = \beta - x y $`} />
                <Math expr={String.raw`$\dot{w} = y z - \gamma w $`} />
              </div>
              <div className="eq-container-inner">
                <Math expr={String.raw`$\alpha = 6 $`} />
                <Math expr={String.raw`$\beta =  11 $`} />
                <Math expr={String.raw`$\gamma = 5 $`} />
              </div>
            </div>
          </div>


        </div>



        <h2 id="generation">Generation of the artwork</h2>
        <p>
          The artwork is generated exclusively by smart contracts - no external resources are needed.
          The contracts and algorithms were heavily optimized to save as much gas as possible such that a code execution within the block gas limit (&lt;30M gas) is possible.
          This means that the artwork can be generated on standard nodes, e.g. by calling the "tokenURI" method on <a href="https://etherscan.io/address/0x1cA15CCdd91b55CD617a48dC9eEFb98CAe224757#code">Etherscan</a>.
          No special nodes or patching are required.
        </p>
        <p>
          Conceptually, the generation of the artwork can be split into three parts:
          <ul>
            <li>Simulation of the dynamical system based on unique initial conditions (randomized at mint).</li>
            <li>Reduction of the multi-dimensional results to a two-dimensional canvas based on the projection settings chosen by the collectors.</li>
            <li>Composition of the final SVG file, incorporating the color grading set by the collectors.</li>
          </ul>
          The individual steps will be explained in more detail in the following sections.
        </p>

        <h4>Simulation</h4>
        <p>
          In the first step, the evolution of the dynamical system is simulated.
          The problems that we are considering here are also known as <a href="https://en.wikipedia.org/wiki/Initial_value_problem">initial value problems</a> (IVPs).
          This means that we start at given initial conditions <Math expr={String.raw`$\xi_0 = (x_0, y_0, z_0)^T$`} /> and follow the evolution of the system described by its differential equations from there.
        </p>

        <p>
          IVPs are frequently found in physics and engineering.
          Therefore, a broad variety of different methods has been developed in the past to treat such problems accurately.
          For Strange Attractors, I used a numerical scheme that is also known as the <a href="https://en.wikipedia.org/wiki/Runge%E2%80%93Kutta_methods#The_Runge%E2%80%93Kutta_method">classical Runge Kutta 4 method</a> (RK4).
          It belongs to a broader class of explicit, single-step methods that compute the new state of the system <Math expr={String.raw`$\xi_{n+1}$`} /> after a certain time <Math expr={String.raw`$\Delta t$`} /> based on its previous state <Math expr={String.raw`$\xi_{n}$`} />.
          In the case of RK4, such an update involves the following computations
          <div className="rk4-container">
          <Math expr={String.raw`$k_0 = f(\xi_n,)$`} />
          <Math expr={String.raw`$k_1 = f(\xi_n + \dfrac{\Delta t}{2} k_0),$`} />
          <Math expr={String.raw`$k_2 = f(\xi_n + \dfrac{\Delta t}{2} k_1),$`} />
          <Math expr={String.raw`$k_3 = f(\xi_n + \Delta t k_2),$`} />
          <Math expr={String.raw`$\xi_{n + 1} = \dfrac{\Delta t}{6} (k_0 + 2 \, k_1 + 2 \, k_2 + k_3), $`} />
          </div>
          where  <Math expr={String.raw`$f$`} /> is known as the right-hand side of the differential equation systems <Math expr={String.raw`$\dot{\xi} = f(\xi)$`} />.
          Starting from a given initial point, the RK4 scheme is then applied iteratively to get from one state to the next for a given amount of steps.
          <Math expr={String.raw`$$\xi_0 \rightarrow \xi_1 \rightarrow \xi_2 \rightarrow \; ...$$`} />
          This scheme is very similar to the Euler method that was used in the <a href="/quest">first puzzle</a>, although it has a significantly higher order to reduce the number of necessary steps to obtain stable solutions.
        </p>

        <p>
            All the involved computations to simulate these systems are performed exclusively by smart contracts that can be executed on regular nodes.
            They can be found at the addresses listed in <a href="/details#systems">Systems</a>.
            One of the biggest challenges in realizing such simulations in Solidity is that they require computations involving decimal numbers, whereas the language currently only supports integers.
            To circumvent this, I relied on a manual implementation of <a href="https://en.wikipedia.org/wiki/Fixed-point_arithmetic">fixed-point number arithmetic</a> similar to <a href="https://brotchain.art">Brotchain</a>.
        </p>

        <h4>Projection</h4>

        <p>
          Every state of the system can be visualised as a point in three-dimensional space.
          Consequently, the evolution of such a system results in a three-dimensional path (or four-dimensional in the case of the Huang system).
          Using the Lorenz system again as an example, such a path would look similar to the cyan curve in the image below.
        </p>

        <div className="image-container">
          <img src={lorenzProjection1a} className="lorenzProjection" />
        </div>

        <p>
          Since the SVG format can only be used to display two-dimensional data, we need to reduce the multi-dimensional point to a 2D canvas first.
          For this, I rely on a procedure known as a linear projection which effectively flattens a three-dimensional structure onto a given projection plane as shown below.
          Put simply, this can also be understood as viewing the three-dimensional curve from a given point-of-view.
        </p>

        <div className="image-container">
          <img src={lorenzProjection1b} className="lorenzProjection" />
        </div>
        <p>
          The exact orientation of the plane is completely arbitrary and will result in a broad variety of different visualizations.
          Collectors can freely choose the projection parameters of their tokens.
          As an example, the projection plane has been tilted and rotated by 60 degrees which results in an entirely different figure for the same Lorenz attractor.
          The possibilities are virtually infinite.
        </p>

        <div className="image-container">
          <img src={lorenzProjection2b} className="lorenzProjection" />
          <img src={lorenzProjection2c} className="border" />
        </div>

        <p>
          The projection plane is spanned by two independent co-vectors.
          This means that the projection plane can not only be oriented as desired but can also include skewed non-unitary basis vectors to introduce additional distortions.
          This enables more general projections and gives the holders even more degrees of freedom to customize their visualizations.
        </p>

        <p>
          Mathematically speaking, the location of a projected 2D point <Math expr={String.raw`$\eta = (x',y')^T$`} /> is computed using the transformation
          <Math expr={String.raw`$$ \eta = \pmatrix{a^T_1 \\ a^T_2} \left( \xi - \Delta \xi \right),$$`} />
          where <Math expr={String.raw`$\xi = (x,y,z)^T$`} /> is the original three-dimensional point,   <Math expr={String.raw`$\Delta \xi$`} /> an offset and <Math expr={String.raw`$a_{1,2}$`} /> the two projection vectors.
          Collectors can hence tune a total of 9 degrees of freedom for three-dimensional systems (12 for the Huang system) using a special <a href="/tools">toolkit</a> on this website.
        </p>

        <p>
          Varying the components of the projection axes individually, however, can be quite tedious and the process will not feel very intuitive.
          To avoid this and deliver a more pleasant experience, I decided to use a spherical map
          <Math expr={String.raw`$$ a_1 = s_1 \pmatrix{
            \cos(\rho) \cos(\phi) - \sin(\theta) \sin(\phi) \sin(\rho) \\
            \cos(\rho) \sin(\phi) + \sin(\theta) \cos(\phi) \sin(\rho) \\
            \cos(\theta) \sin(\rho)
            }
            $$`} />
          <Math expr={String.raw`$$
            a_2 = s_2 \pmatrix{
              \cos(\rho+\alpha) \cos(\phi) - \sin(\theta) \sin(\phi) \sin(\rho+\alpha) \\
              \cos(\rho+\alpha) \sin(\phi) + \sin(\theta) \cos(\phi) \sin(\rho+\alpha) \\
              \cos(\theta) \sin(\rho+\alpha)
              }
            $$`} />
          to compute the projection axes instead.
          Here, <Math expr={String.raw`$\phi$`} /> denotes the azimuth, <Math expr={String.raw`$\theta$`} /> the altitude, <Math expr={String.raw`$\rho$`} /> the rotation, <Math expr={String.raw`$\alpha$`} /> the angle between the axes, and <Math expr={String.raw`$s_{1,2}$`} /> the two scaling parameters, corresponding to the sliders in the <a href="/tools">toolkit</a>.
          Spherical maps have the advantage that they enable the observer to be rotated around the piece in 3D space, which delivers a much more natural customization process.
        </p>

        <p>
          In the case of the Huang attractor, I relied on the same idea although approaching it slightly differently due to the added fourth dimension.
          First, I defined a set of orthogonal unit basis-vectors by rotating the standard basis sequentially in the <Math expr={String.raw`$e_x-e_{y,z,w}$`} /> planes by the angles <Math expr={String.raw`$\phi, \theta, \omega$`} /> using rotation matrices
          <Math expr={String.raw`$$\pmatrix{e^T_1\\e^T_2\\e^T_3\\e^T_4} = \pmatrix{
            \cos \theta \cos\phi \cos \omega & \cos \theta \cos\omega \sin\phi & \cos\omega \sin \theta & \sin \omega \\
            - \sin\phi& \cos\phi& 0& 0\\
            - \cos\phi \sin\theta & \sin\theta \sin\phi & \cos\theta & 0 \\
           -\cos \theta \cos\phi \sin \omega & -\cos \theta \sin\omega \sin\phi & \sin\omega \sin \theta & \cos \omega}. $$`} />
          Here, I chose the first rotated basis-vector as the first projection axis <Math expr={String.raw`$a_1 = s_1 e_1$`} />.
          This choice allows the computation of all rotation angles given the components of the projection axis.
          To construct the second projection axis, I used generalized spherical coordinates in the four-dimensional rotated basis system.
          Considering that the choice for  <Math expr={String.raw`$a_1$`} /> implies  <Math expr={String.raw`$e_1$`} /> as zenith direction, the second projection axis is readily computed by
          <Math expr={String.raw`$$a_2 = s_2 \; (e_1, e_2, e_3, e_4) \cdot \pmatrix{
            \cos \alpha \\ \cos\delta_1  \cos\delta_2 \sin\alpha \\ \sin \delta_1 \cos\delta_2 \sin\alpha \\ \sin\delta_2\sin\alpha}, $$`} />
          where <Math expr={String.raw`$\alpha, \delta_1, \delta_2$`} /> correspond to the angle between the axes and the azimuth and altitude in the 3D subspace orthogonal to  <Math expr={String.raw`$a_1$`} />.
          The coefficients <Math expr={String.raw`$s_1, s_2$`} /> are again the scaling factors.
        </p>

        <h4 id="assembling-the-svg">Assembling the SVG</h4>
        <p>
          After projecting the points to two dimensions, they can now be used to compose the artwork in the SVG format.
          This is done by two rendering contracts:
          <ul>
            <li>The PointRenderer, used exclusively for the Dadras-Momeni system (<span className="address"><a href="https://etherscan.io/address/0xdc9b93DE5f92c8bCf52b266036710C1be6A39827" target="blank">0xdc9b93...9827</a></span>).</li>
            <li>The LineRenderer, used for the other systems (<span className="address"><a href="https://etherscan.io/address/0x46eEfB5032C055D31CD0653C7Daf316FdA9C43Bb" target="blank">0x46eEfB...43Bb</a></span>).</li>
          </ul>
          As the name suggests, the PointRenderer generates SVGs that are composed of individual points using <code>&lt;polyline&gt;</code> elements with predefined markers, whereas the LineRenderer generates lines using <code>&lt;path&gt;</code> elements.
        </p>

        <p>
          The latter one is especially challenging since drawing a straight line between points will lead to visible edges in the curves.
          To avoid this, one could simply increase the number of points along the curve.
          This, however, increases the required amount of gas drastically which significantly shortens the length of the curves that can be feasibly rendered.
          Instead, one can also use smooth <a href="https://en.wikipedia.org/wiki/B%C3%A9zier_curve#Cubic_B%C3%A9zier_curves">cubic Bezier curves</a>, which I used in this project.
          This has the downside of additional complexity because one needs to specify additional control points for the spline interpolation.
          Here, I leveraged the fact that we are dealing with differential equations which provide tangents to every given point of the solution.
          The control points are then computed by following these tangents for <Math expr={String.raw`$\Delta t / 3$`} />, which produced the best visual results.
          This choice can also be motivated mathematically by equating the derivatives of the Bezier curves at the start and the end of each line segment with the corresponding values provided by the differential equations.
        </p>

        <p>
          Both renderers operate on the same basic principle that is to compose the final SVG by concatenating the strings of the individual SVG elements.
          When I started working on the project, I oriented myself on how other codes such as <a href="https://solseedlings.art/">sol.Seedlings</a> achieve this.
          They commonly use repeated calls like <code>svgBuffer = abi.encodePacked(svgBuffer, ...)</code> to append new elements to a buffer.
          This approach, however, is quite inefficient because <code>abi.encodePacked</code> reallocates new memory in every call and just copies the content from the old buffer.
          The old space remains unused (since there is no garbage collection in Solidity) and more and more gas accures in every repetition.
          I soon realized that this approach is not feasible for Strange Attractors, because I needed to concatenate a huge number of elements, resulting in the gas budget being completely used up by this unnecessary overhead.
        </p>

        <p>
          So I had to come up with a more efficient solution.
          The simplest idea is to avoid the repeated allocation altogether by allocating sufficient buffer space once, which is subsequently filled.
          Most programming languages already offer solutions for such problems in the form of (resizable) containers.
          However, Solidity has no built-in way for this and I ended up building something similar myself.
        </p>
        <p>
          Before I jump into the implementational details, let's first take a look at how the EVM handles dynamically allocated arrays like <code>bytes data = new bytes(size)</code> under the hood.
          In memory, the data is organized as follows.
        </p>

        <div id="memory-sketch">
          <div>
            <div>
              size
            </div>
            <div>
              0x00
            </div>
          </div>
          <div>
            <div>
              data[0]
            </div>
            <div>
              0x20
            </div>
          </div>
          <div>
            <div>
              data[1]
            </div>
            <div>
              0x21
            </div>
          </div>

          <div>
            <div>
              ...
            </div>
            <div>

            </div>
          </div>
          <div>
            <div>
              data[size-1]
            </div>
            <div>
              0x20 + size - 1
            </div>
          </div>
        </div>

        <p>
          The first 256 bits (32 bytes) are reserved to store the size of the array.
          The actual data follows afterwards.
          So the variable <code>bytes data</code> actually just contains the memory address to the first element of the array - the size.
          The address itself + the value at that location thus tells Solidity which range of memory is used by any given array and allows it to perform safe read and write access.
        </p>
        <p>
          My approach to creating dynamically fillable buffers was to allocate a container <code>bytes cont</code> with a given size/capacity that contains the data of the actual buffer (shown as red below).
          The inner array has to follow the same memory layout as standard arrays, however, we can change its length and resize it at will (provided it remains within the container capacity).
        </p>
        <div id="memory-sketch">
          <div>
            <div>
              capacity
            </div>
            <div>
              0x00
            </div>
          </div>

          <div className="inner-array">
            <div>
              cont[0...32], length
            </div>
            <div>
              0x20
            </div>
          </div>

          <div className="inner-array">
            <div>
              cont[32], buf[0]
            </div>
            <div>
              0x40
            </div>
          </div>

          <div className="inner-array">
            <div>
              cont[33], buf[1]
            </div>
            <div>
              0x41
            </div>
          </div>

          <div className="inner-array">
            <div>
              ...
            </div>
            <div>

            </div>
          </div>
        </div>
        <p>
          With this, appending strings to a buffer becomes trivial: Compute the current end of the buffer from its length, copy the bytes of the string to this location, and update the buffer's length afterwards according to the amount of copied bytes.
          Also, integrating this with the rest of solidity is trivial since we can just create a new variable <code>bytes buf</code> and assign the correct address using assembly <code>buf := add(cont, 0x20)</code>.
          Solidity will correctly interpret the memory content because the nested array follows the same memory layout as standard dynamical arrays.
        </p>

        <h4>Coloring the curves</h4>
        To be continued...

      </div>
    </div >
  );
}

export default About;
