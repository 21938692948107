import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './routes';
import { NavBar } from './components/NavBar';
import { Footer } from './components/Footer';
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";

const App = () => {

  ReactGA.initialize('UA-211321024-1', [])
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="App">
    <Helmet>
        <title>Strange Attractors</title>
        <meta name="description" content="Simulating chaotic, three-dimensional systems directly on the ethereum blockchain. Strange Attractors is an interactive, on-chain, generative art, NFT project that simulates three-dimensional, chaotic systems using nothing but an ethereum smart contract." />
    </Helmet>

      <div className="content">
        <Router>
          <NavBar />
          <div className="main-content">
            <Routes />
          </div>
          <Footer />
        </Router>
      </div>
    </div>
  );
}

export default App;
