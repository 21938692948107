import "./Home.css";
import React, { useState } from "react";

import lorenzXZ from "../../assets/lorenzYZ.svg";
import { Typography, Stack, Container, Link, Divider } from "@mui/material";
import { useHistory } from "react-router-dom";

import Countdown from "react-countdown";

const Home = () => {
  const history = useHistory();

  return (
    <div className="home">
      <img src={lorenzXZ} className="cover-img" />
      {/* <h1>Strange Attractors</h1> */}

      <h4>
        Simulating chaotic, three-dimensional systems directly on the Ethereum
        blockchain.
      </h4>

      <div className="goto-container">
        <input
          type="button"
          value="ABOUT"
          className="goto-button"
          onClick={() => {
            history.push("about");
          }}
        />
        {/* <input type="button" value="MINT" className="goto-button" onClick={() => { history.push('mint') }} /> */}
        <input
          type="button"
          value="CONTEST"
          className="goto-button"
          onClick={() => {
            history.push("contest");
          }}
        />
        <input
          type="button"
          value="CUSTOMIZATION"
          className="goto-button"
          onClick={() => {
            history.push("tools");
          }}
        />
        <input
          type="button"
          value="COMPLETION"
          className="goto-button"
          onClick={() => {
            history.push("fullset");
          }}
        />
      </div>

      <div className="social-media-links">
        <span>
          <a href="https://discord.gg/SyYCfrtzkP" target="_blank">
            Discord
          </a>
        </span>
        <span>/</span>
        <span>
          <a href="https://twitter.com/StrngeAttrctors" target="_blank">
            Twitter
          </a>
        </span>
        <span>/</span>
        <span>
          <a
            href="https://opensea.io/collection/strange-attractors-on-chain/"
            target="_blank"
          >
            Opensea
          </a>
        </span>
        <span>/</span>
        <span>
          <a
            href="https://etherscan.io/address/0x1cA15CCdd91b55CD617a48dC9eEFb98CAe224757"
            target="_blank"
          >
            Contract
          </a>
        </span>
      </div>

      {/* <div className="quickLinks">
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={4}>
          <div>
            <a>Contract</a>
          </div>
          <span>&#183;</span>
          <span>&#183;</span>
          <div>asdfas</div>
        </Stack>
      </div> */}
    </div>
  );
};

export default Home;
