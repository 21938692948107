import './Quest2.css';
import mask from './mask.jpeg';
import { MathJaxContext, MathJax } from 'better-react-mathjax';

const Math = (props) => {
  const mathJaxConfig = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [["$", "$"]],
      displayMath: [["$$", "$$"]]
    }
  };

  return (
    <span className="equation">
      <MathJaxContext config={mathJaxConfig} version={3}>
        <MathJax inline>
          {props.expr}
        </MathJax>
      </MathJaxContext>
    </span>
  );
}



const Quest = () => {

  return (
    <div className="quest2">
      <div className="textblock">

        <div className="img-container">
          <img src={mask} />
        </div>
        <p>
          After retrieving the Heart of the Strange Tractor, our two treasure hunters got into a huge fight about its future.
          While Euler was pushing to exhibit it in a public place for everyone to admire, Lorenz wanted to keep it exclusively to themselves.
          The fight escalated with Lorenz running off, hiding the gem in a secret place.
          Needless to say, they did not exchange a single word ever again.
        </p>

        <p>
          Many years later, Lorenz got very sick.
          Deeply saddened by the thought that his treasure would be lost and forgotten once again, he decided to give away its location to Euler and wrote him a letter.
          In case that the letter found its way into the wrong hands, Lorenz decided to encrypt the message:
        </p>
        <p className="letter">
          In my entire life, there were only two moments other than my birth in which I stood completely still.
          Look through them like a mask, and you will see the way that you need to follow.
          Make a step for every Strange Tractor, and then start digging.
          But make sure to avoid quadrant IV - nothing good ever happens there...
        </p>
        <p>
          Unfortunately, all the years also took a toll on Euler, and he couldn't figure out what Lorenz was referring to.
          Can you help him to decipher the text and to retrieve the gem once again?
        </p>

        <p>
          Tweet your answers at <a href="https://twitter.com/strngeattrctors" target="_blank">@StrngeAttrctors</a>. The first to provide the correct value wins a secure minting slot.
        </p>

      </div>
    </div >
  );
}

export default Quest;
