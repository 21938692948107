import "./About.css";
import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { Link, withRouter } from "react-router-dom";
import { Grid, Hidden, Box } from "@mui/material";

import lorenzXZ from "../../assets/lorenzXZ.svg";
import lorenzYZ from "../../assets/lorenzYZ.svg";
import lorenzXY from "../../assets/lorenzXY.svg";

const About = () => {
  return (
    <div className="about">
      <Helmet>
        <title>About | Strange Attractors</title>
        <meta
          name="description"
          content="Strange Attractors is an interactive, on-chain, generative art, NFT project that simulates three-dimensional, chaotic systems using nothing but an ethereum smart contract."
        />
      </Helmet>

      <Grid zeroMinWidth container spacing={0} className="gallery">
        <Grid item zeroMinWidth xs={12} sm={6} md={4}>
          <img src={lorenzXZ} />
        </Grid>
        <Grid item zeroMinWidth xs={false} sm={false} md={4}>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <img src={lorenzYZ} />
          </Box>
        </Grid>
        <Grid item zeroMinWidth xs={false} sm={6} md={4}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <img src={lorenzXY} />
          </Box>
        </Grid>
      </Grid>

      <div className="textblock">
        <p className="elevator">
          Strange Attractors is an interactive, on-chain, generative NFT project
          that simulates multi-dimensional, chaotic systems using nothing but an
          Ethereum smart contract.
        </p>

        <p>
          The collection is composed of 5 distinct{" "}
          <a href="/details#systems">chaotic systems</a> that each develop their
          own characteristic Strange Attractor. Each system contributes 128
          unique pieces that represent simulations of their evolution based on
          randomized initial conditions (generated at mint).
        </p>

        <p>
          Every step in the generation of the artworks, from performing the
          simulations to the rendering the images, is performed{" "}
          <a href="/details#generation">exclusively by Ethereum nodes</a>{" "}
          without the need for external dependencies. This ensures that the art
          itself will be available as long as the ethereum blockchain exists.
          {/* This is also known as on-chain generative art. */}
        </p>

        <p>
          While previous on-chain art projects were mainly concerned with
          two-dimensional algorithms, the simulations performed here are
          inherently multi-dimensional. This makes Strange Attractors the first
          project to directly manipulate three- and four-dimensional structures
          on-chain (see the <a href="/details">Details</a> page for more info).
        </p>

        {/* 
          The resulting three-dimensional structure is reduced to a two-dimensional SVG canvas directly within the contract.
        </p> */}

        <p>
          The contract produces the artwork by projecting the resulting
          multi-dimensional structures onto a two-dimensional SVG canvas.
          Collectors can greatly influence this process, allowing them to freely{" "}
          <a href="/tools">customize</a> various parameters of their pieces such
          as the viewing angle, rotations and distortions, as well as the colour
          grading. You can also check out this <a href="/demo">demo toolkit</a>{" "}
          if you don't hold any tokens.
        </p>

        {/* <p>
          Strange Attractors brings complex simulations from the realm of physics and engineering over to Solidity and demonstrates that even challenging numerical computations can be performed using smart contracts.
          While previous on-chain art projects were mainly concerned with two-dimensional algorithms, the simulations performed here are inherently multi-dimensional.
          This makes Strange Attractors the first on-chain art project to directly manipulate three-dimensional structures (see the <Link to="details">Details</Link> page for more info).
        </p> */}

        <p>
          <h4>Disclaimer</h4>
          Many aspects of this project are highly experimental. While the
          contracts have been tested extensively, they have not been audited by
          a third party. By buying into the project, you acknowledge and accept
          the risks involved. You can review the code on{" "}
          <a href="https://etherscan.io/address/0x1cA15CCdd91b55CD617a48dC9eEFb98CAe224757">
            etherscan
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default About;
