import './ColormapCreator.css';

import { useEffect, useState } from "react";
import NumberSlider from '../MyTools/NumberSlider';

import { useDebouncedCallback } from 'use-debounce';





export const ColormapCreator = ({ colorAnchors, setColorAnchors }) => {

    const addSlider = () => {
        setColorAnchors([...colorAnchors, {
            id: Date.now().toString(),
            color: '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0'),
            position: Math.floor(Math.random() * 255),
            lastUpdate: Date.now()
        }])
    }

    const handleColorChange = useDebouncedCallback((id, color) => {
        let newSliders = [...colorAnchors]
        let slider = colorAnchors.filter((slider) => slider.id === id)[0];
        slider.color = color;
        slider.lastUpdate = Date.now()
        setColorAnchors(newSliders);
    },
        100);

        const handlePositionChange = (id, value) => {
        let newSliders = [...colorAnchors]
        let slider = newSliders.filter((slider) => slider.id === id)[0];
        slider.position = Number(value);
        setColorAnchors(newSliders);
    }

    const removeColorSlider = (id) => {
        setColorAnchors((sliders) => sliders.filter((slider) => slider.id !== id));
    };

    const hexToRgb = (hex) => {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16)
        ] : null;
    };

    const rgbToHex = ([r, g, b]) => {
        function componentToHex(c) {
            var hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        }

        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    };

    const interpolateRGB = (rgb1, rgb2, fraction) => {
        let result = [0, 0, 0]
        for (let i = 0; i < 3; i++) {
            result[i] = Math.round(rgb1[i] + fraction * (rgb2[i] - rgb1[i]));
        }
        return result;
    };

    const pairwise = (arr, func) => {
        for (var i = 0; i < arr.length - 1; i++) {
            func(arr[i], arr[i + 1])
        }
    }

    const computeColors = () => {

        if (colorAnchors.length == 0) {
            return new Array(256).fill("#000000");
        }

        var colors = new Array(256);

        const sorted = [...colorAnchors].sort((slider1, slider2) => slider1.position - slider2.position)

        // Extrapolating colors below the leftmost slider
        for (let idx = 0; idx <= sorted[0].position; idx++) {
            colors[idx] = sorted[0].color;
        }

        if (colorAnchors.length >= 2) {

            // Interpolating colors between sliders
            pairwise(sorted, (left, right) => {
                let rgb1 = hexToRgb(left.color);
                let rgb2 = hexToRgb(right.color);
                for (let idx = left.position; idx < right.position; idx++) {
                    let factor = (idx - left.position) / (right.position - left.position);
                    colors[idx] = rgbToHex(interpolateRGB(rgb1, rgb2, factor));
                }
            })
        }

        // Extrapolating colors above the rightmost slider
        for (let idx = sorted[sorted.length - 1].position; idx < 256; idx++) {
            colors[idx] = sorted[sorted.length - 1].color;
        }

        return colors;
    }

    const [previewColors, setPreviewColors] = useState([]);
    useEffect(async () => {
        setPreviewColors(computeColors)
    }, [colorAnchors])

    return (
        <div className="colormap-creator">
            <div className="preview-row">
                <input type="button" onClick={addSlider} value="+" className="add-slider-button" />

                <div className="colormap-preview-container">
                    <div className="colormap-preview">
                        {previewColors.map((color, idx) => <div className="preview-color" key={idx} style={{ backgroundColor: color }}></div>)}
                    </div>
                </div>
            </div>

            <div className="colormap-sliders">
                {colorAnchors.map(({ id, color, position }) => {
                    return (
                        <div key={id} className="colormap-slider-row">
                            <input className="remove-slider-button" type="button" onClick={() => removeColorSlider(id)} value="&times;" />
                            <input className="color-picker" type="color" value={color} onChange={(e) => handleColorChange(id, e.target.value)} />
                            <div className="position-slider">
                                <NumberSlider min={0} max={255} stepNumber={1} stepSlider={1} value={position} onChange={(e)=> handlePositionChange(id, e.target.value)} />
                            </div>
                        </div>
                    )
                })}
            </div>

        </div>
    );
}


export default ColormapCreator;