import "./NavBar.css";
import React from "react";
import { withRouter } from "react-router-dom";
import { Box } from "@mui/material";

const InlineResponsiveSwitch = (switches) => {
  const displayProto = {
    xs: "none",
    sm: "none",
    md: "none",
    lg: "none",
    xl: "none",
  };

  return (
    <span>
      {Object.keys(switches).map((key, index) => {
        let disp = { ...displayProto };
        disp[key] = "inline";
        return (
          <Box key={index} sx={{ display: disp }}>
            {switches[key]}
          </Box>
        );
      })}
    </span>
  );
};

const NavBar = (props) => {
  const { match, location, history } = props;

  const setCurrentLocation = async (thisLocation) => {
    return thisLocation == location.pathname ? "currentLocation" : "";
  };

  return (
    <div className="navbar">
      <div className="left">
        <a href="/about">About</a>
        {InlineResponsiveSwitch({
          xs: <br />,
          sm: " / ",
          md: " / ",
          lg: " / ",
          xl: " / ",
        })}
        <a href="/details">
          {InlineResponsiveSwitch({
            xs: "Details",
            sm: "Details",
            md: "Technical Details",
            lg: "Technical Details",
            xl: "Technical Details",
          })}
        </a>
      </div>

      <div className="title center">
        <a href="/">Strange Attractors</a>
      </div>

      <div className="right">
        {/* <a href="mint">Mint</a>
        {InlineResponsiveSwitch({
          xs: <br />,
          sm: ' / ',
          md: ' / ',
          lg: ' / ',
          xl: ' / ',
        })} */}
        <a href="/tools">Tools</a>
        {InlineResponsiveSwitch({
          xs: <br />,
          sm: " / ",
          md: " / ",
          lg: " / ",
          xl: " / ",
        })}
        <a href="/fullset">Complete</a>
      </div>
    </div>
  );
};

export default withRouter(NavBar);
