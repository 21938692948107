import './Tools.css';
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from "react-helmet";

import { TokenSelector } from '../../components/MyTools//TokenSelector';
import { ConnectButton } from '../../components/MyTools/ConnectButton';

import { ProjectionExpert, ProjectionSliders, ProjectionSliders4D } from '../../components/ProjectionTools';

import { ColormapCreator } from '../../components/ColormapCreator';

import * as utils from '../../components/MyTools/utils.js';
import * as utils2 from '../../components/MyTools/utils2.js';
import { SvgWindow } from '../../components/SvgWindow';


import { LabeledSlider } from '../../components/MyTools/NumberSlider';
import { Explanation } from './Explanation.js';

import { useDebouncedCallback } from 'use-debounce';



const Tools = () => {
  const [chain, setChainId] = useState(0);
  const [account, setAccount] = useState('');

  const [selectedToken, setSelectedToken] = React.useState(-1);
  const [svg, setSvg] = useState("%3Csvg width='1024' height='1024' viewBox='-4096 -4096 8192 8192' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='-4096' y='-4096' width='100%25' height='100%25' fill='black'/%3E%3C/svg%3E");

  const [projectionParameters, setProjectionParameters] = useState({
    axis1: [1, 0, 0],
    axis2: [0, 0, 1],
    offset: [3, 3, 3],
  });
  const [colorAnchors, setColorAnchors] = useState([])
  const [renderSize, setRenderSize] = useState(20)


  const [rendering, setRendering] = useState(false);
  const [renderTrigger, setRenderTrigger] = useState(false);

  useEffect(() => {
    if (renderTrigger) {
      render();
      setRenderTrigger(false);
    }
  }, [renderTrigger]);

  const reloadToken = async (tokenId) => {
    if (!utils2.isValidTokenId(tokenId)) {
      console.error("Invalid tokenId:", tokenId);
      return;
    }

    try {
      console.log('Loading token', tokenId);
      let projectionParameters = await utils.fetchTokenProjectionParameters(tokenId);
      setProjectionParameters(utils.parseProjectionParameters(projectionParameters));
      let colorAnchors = await utils.fetchTokenColorAnchors(tokenId);
      setColorAnchors(colorAnchors);
      let renderSize = (await utils.getToken(tokenId)).renderSize;
      setRenderSize(renderSize);
      setRenderTrigger(true);
    } catch (err) {
      console.error(err);
    }
  }

  const onTokenSelect = async (e) => {
    let tokenId = e.target.value;
    setSelectedToken(tokenId);
    if (tokenId >= 0) {
      await reloadToken(tokenId);
    }
  }

  const saveSettings = async () => {
    utils2.downloadJSON(JSON.stringify({
      tokenId: selectedToken,
      version: "1.0.0",
      projectionParameters, colorAnchors, renderSize
    }, null, 4), utils2.getTokenName(selectedToken) + "-settings.json");
  }

  const loadSettings = async (e) => {
    let file = e.target.files[0];
    const reader = new FileReader();

    const handleRead = (e) => {
      const content = reader.result;
      let settings = JSON.parse(content);
      console.log('Loading settings', settings);

      setColorAnchors(settings.colorAnchors);
      setRenderSize(settings.renderSize);
      setProjectionParameters(settings.projectionParameters);
      setRenderTrigger(true);
    }

    reader.onloadend = handleRead;
    reader.readAsText(file);
  };

  const render = async () => {
    if (!utils2.isValidTokenId(selectedToken)) {
      console.error("Invalid tokenId:", selectedToken);
      return;
    }

    setRendering(true);
    const _projParams = utils.convertProjectionParameters(projectionParameters);
    setSvg(await utils.renderToken(selectedToken, _projParams, colorAnchors, renderSize));
    setRendering(false);
  }

  const [autoRender, setAutoRender] = useState(true);
  const doAutoRender = useDebouncedCallback(() => {
    if (autoRender) {
      render()
    }
  }, 700);

  useEffect(doAutoRender, [projectionParameters, renderSize, colorAnchors])

  const toggleRenderButton = async () => {
    if(!autoRender) {
      await render()
    }
    setAutoRender(!autoRender)
  }

  const downloadSVG = () => {
    let filename = utils2.getTokenName(selectedToken) + ".svg";
    utils2.downloadSVG(unescape(svg), filename);
  }

  const downloadPNG = () => {
    let filename = utils2.getTokenName(selectedToken) + ".png";
    utils2.downloadPNG(unescape(svg), filename);
  }

  const [resetProjection, setResetProjection] = useState(false);
  const [resetColors, setResetColors] = useState(false);
  const [resetSize, setResetSize] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);

  const doReset = () => {
    if (!utils2.isValidTokenId(selectedToken)) {
      console.error("Invalid tokenId:", tokenId);
      return;
    }

    if (resetProjection || resetColors || resetSize) {
      utils.resetRenderParameters(selectedToken, { resetProjection, resetColors, resetSize });
    } else {
      alert("Please select one or more parameters that should be reset.")
    }
  }

  const hiddenFileInput = useRef(0);
  const [foldedOut, setFoldedOut] = useState(false);

  return (
    <div className="tools">
      <Helmet>
        <title>Token Customizer | Strange Attractors</title>
        <meta name="description" content="A dedicated tool set that allows Strange Attractor collectors to customize their pieces. New settings can be previewed before committing the changes to the blockchain." />
      </Helmet>

      <div>
        <div className="title">
          <h2>Token Customizer</h2>
          <h4>Strange Attractor customization toolbox for collectors.</h4>
          <a href="demo"><h5>Goto Demo</h5></a>
        </div>

        <ConnectButton {...{ account, setAccount, setChainId }} />
        {(account !== '' && window.ethereum.chainId != 1) && <h2 className="mainnet-message">Please connect Metamask to the mainnet</h2>}


        <div className={(account == '' || window.ethereum.chainId != 1) ? "disabled" : ""}>
          <div className="tools-container">

            <div className="left">


              <SvgWindow data={svg} loading={rendering} />

              <div className="button-box">
                <div className="token-selector">
                  <TokenSelector onChange={onTokenSelect} account={account} />
                </div>
                <input type="number" style={{ display: "none" }} onInput={e => { if (e.target.value >= 0) onTokenSelect(e) }} />
                <div >
                  <input type="button" onClick={() => reloadToken(selectedToken)} value="Reload Token" className="button" disabled={!utils2.isValidTokenId(selectedToken)}></input>
                </div>
              </div>

              <div className="button-box">
                <div className={autoRender && utils2.isValidTokenId(selectedToken)? "invert" : ""}>
                  <input type="button" onClick={toggleRenderButton} value="Render" className="button" disabled={!utils2.isValidTokenId(selectedToken)}></input>
                </div>
                <div>
                  <input type="button" onClick={downloadSVG} value="Download SVG" className="button" disabled={!utils2.isValidTokenId(selectedToken)}></input>
                </div>
                <div>
                  <input type="button" onClick={downloadPNG} value="Download PNG" className="button" disabled={!utils2.isValidTokenId(selectedToken)}></input>
                </div>
              </div>

              <div className="button-box">
                <div>
                  <input type="button" onClick={saveSettings} value="Export Settings" className="button" disabled={!utils2.isValidTokenId(selectedToken)}></input>
                </div>
                <div>
                  <input type="file" ref={hiddenFileInput} style={{ display: "none" }} onChange={loadSettings} />
                  <input type="button" value="Import Settings" onClick={() => hiddenFileInput.current.click()} disabled={!utils2.isValidTokenId(selectedToken)} />
                </div>
              </div>

              <div className="button-box">
                <div>
                  <input type="button" onClick={() => utils.uploadProjectionParameters(selectedToken, projectionParameters)} value="Commit Projection" className="button" disabled={!utils2.isValidTokenId(selectedToken)}></input>
                </div>
                <div>
                  <input type="button" onClick={() => utils.uploadColorAnchors(selectedToken, colorAnchors)} value="Commit Colors" className="button" disabled={!utils2.isValidTokenId(selectedToken)}></input>
                </div>
              </div>

              <div className="button-box">
                <div>
                  <input type="button" onClick={() => utils.uploadRenderSize(selectedToken, renderSize)} value="Commit Size" className="button" disabled={!utils2.isValidTokenId(selectedToken)}></input>
                </div>

                <div className={resetOpen ? "invert" : ""}>
                  <input type="button" onClick={() => setResetOpen(!resetOpen)} value="Reset Defaults" className="button" disabled={!utils2.isValidTokenId(selectedToken)}></input>
                </div>

                <div className={foldedOut ? "invert" : ""}>
                  <input type="button" onClick={() => setFoldedOut(!foldedOut)} value="??" className="button" />
                </div>

              </div>



              {resetOpen &&
                <div className="button-box">

                  <div>
                    <input type="button" onClick={doReset} value="Do Reset" className="button"></input>
                  </div>

                  <div className={resetProjection ? "invert" : ""}>
                    <input type="button" onClick={(e) => setResetProjection(!resetProjection)} value="Projection?" className="button"></input>
                  </div>

                  <div className={resetColors ? "invert" : ""}>
                    <input type="button" onClick={(e) => setResetColors(!resetColors)} value="Colors?" className="button"></input>
                  </div>

                  <div className={resetSize ? "invert" : ""}>
                    <input type="button" onClick={(e) => setResetSize(!resetSize)} value="Size?" className="button"></input>
                  </div>
                </div>
              }
            </div>


            <div className="right">
              <div className="border">
                <div className="paletteTitle">Projection</div>
              </div>
              {projectionParameters.axis1.length == 3 &&
                <div className="border">
                  <ProjectionSliders {...{ projectionParameters, setProjectionParameters }} />
                </div>
              }
              {projectionParameters.axis1.length == 4 &&
                <div className="border">
                  <ProjectionSliders4D {...{ projectionParameters, setProjectionParameters }} />
                </div>
              }
              <div className="border">
                <ProjectionExpert {...{ projectionParameters, setProjectionParameters }} />
              </div>
              <div className="border">
                <div className="paletteTitle ">Style</div>
              </div>
              <div className="size-slider-box border">
                <LabeledSlider label="Size" min={0} max={128} value={renderSize} stepNumber={1} onChange={(e) => setRenderSize(e.target.value)} />
              </div>
              <div className="colormap border">
                <ColormapCreator {...{ colorAnchors, setColorAnchors }} />
              </div>
            </div>
          </div>
        </div>
      </div >

      <div className="border">
        <Explanation foldedOut={foldedOut} />
      </div>

    </div >
  );
}

export default Tools;
