import { LabeledSlider } from '../MyTools/NumberSlider.js';
import React, { useEffect, useState } from 'react';
import { norm, divide, cross, dot, subtract, inv, matrix, multiply, transpose, max } from 'mathjs';

export const ProjectionSliders = ({ projectionParameters, setProjectionParameters }) => {
    const [selfUpdate, setSelfUpdate] = useState(false);

    const [azi, setAzi] = useState(0);
    const [alt, setAlt] = useState(0);
    const [angle, setAngle] = useState(90);
    const [delta1, setDelta1] = useState(0);
    const [delta2, setDelta2] = useState(0);
    const [omega, setOmega] = useState(0);
    const [scale1, setScale1] = useState(2);
    const [scale2, setScale2] = useState(2);

    const deg = Math.PI / 180;

    const cos = (x) => Math.cos(x * deg);
    const sin = (x) => Math.sin(x * deg);
    const round = (x, digits) => Number(x.toFixed(digits));
    const clip1 = (x) => Math.max(-1, Math.min(1, x));

    const computeAxis1 = ({ azi, alt, omega, scale1 }) => {
        let dir = [
            cos(alt) * cos(azi) * cos(omega),
            cos(alt) * cos(omega) * sin(azi),
            cos(omega) * sin(alt),
            sin(omega)
        ];
        return dir.map(x => x * scale1);
    }

    const computeAxis2 = ({ azi, alt, omega, angle, delta1, delta2, scale2 }) => {
        let dir = [
            cos(alt) * cos(angle) * cos(azi) * cos(omega) - sin(angle) * (cos(delta2) * (cos(delta1) * sin(azi) + cos(azi) * sin(alt) * sin(delta1)) + cos(alt) * cos(azi) * sin(delta2) * sin(omega)),
            cos(azi) * cos(delta1) * cos(delta2) * sin(angle) + sin(azi) * (cos(alt) * cos(angle) * cos(omega) - sin(angle) * (cos(delta2) * sin(alt) * sin(delta1) + cos(alt) * sin(delta2) * sin(omega))),
            cos(alt) * cos(delta2) * sin(angle) * sin(delta1) + sin(alt) * (cos(angle) * cos(omega) - sin(angle) * sin(delta2) * sin(omega)),
            cos(omega) * sin(angle) * sin(delta2) + cos(angle) * sin(omega)
        ];
        return dir.map(x => x * scale2);
    }

    const updateProjectionParameters = (allAngles) => {
        setSelfUpdate(true);
        setProjectionParameters({
            ...projectionParameters,
            axis1: computeAxis1(allAngles).map(x => round(x, 2)),
            axis2: computeAxis2(allAngles).map(x => round(x, 2))
        });
    }

    const updateAngles = () => {
        console.log('updating angles form config')
        let a1 = projectionParameters.axis1;
        let a2 = projectionParameters.axis2;

        let l1 = norm(a1);
        let l2 = norm(a2);

        a1 = divide(a1, l1);
        a2 = divide(a2, l2);

        console.log(a1, a2)

        let omega = Math.asin(clip1(a1[3]));
        let alt = Math.asin(clip1(a1[2] / Math.cos(omega)));
        let azi = Math.asin(clip1(a1[1] / (Math.cos(omega) * Math.cos(alt))));



        console.log('first angles', omega, alt, azi)

        let rotInv = matrix([
            [Math.cos(alt) * Math.cos(azi) * Math.cos(omega), Math.cos(alt) * Math.cos(omega) * Math.sin(azi), Math.cos(omega) * Math.sin(alt), Math.sin(omega)],
            [-Math.sin(azi), Math.cos(azi), 0, 0],
            [-Math.cos(azi) * Math.sin(alt), -Math.sin(alt) * Math.sin(azi), Math.cos(alt), 0],
            [-Math.cos(alt) * Math.cos(azi) * Math.sin(omega), -Math.cos(alt) * Math.sin(azi) * Math.sin(omega), -Math.sin(alt) * Math.sin(omega), Math.cos(omega)]
        ])

        console.log(rotInv)

        let B = multiply(rotInv, a2).toArray();
        console.log(B)

        let angle = Math.acos(clip1(dot(a1, a2)));
        let delta2 = Math.asin(clip1(B[3] / Math.sin(angle)));
        let delta1 = Math.asin(clip1(B[2] / (Math.cos(delta2) * Math.sin(angle))));

        console.log('second angles', angle, delta2, delta1)

        azi = (azi / deg + 360) % 360
        alt = (alt / deg + 90) % 180 - 90
        omega = (omega / deg + 90) % 180 - 90
        angle = (angle / deg + 360) % 180
        delta1 = (delta1 / deg + 360) % 360
        delta2 = (delta2 / deg + 90) % 180 - 90

        setAzi(round(azi, 1));
        setAlt(round(alt, 1));
        setOmega(round(omega, 1));
        setAngle(round(angle, 1));
        setDelta1(round(delta1, 1));
        setDelta2(round(delta2, 1));
        setScale1(round(l1, 2));
        setScale2(round(l2, 2));


        // const f = ([azi, alt, omega, angle, delta1, delta2, scale1, scale2]) => {
        //     let res1 = subtract(computeAxis1({ azi, alt, omega, angle, delta1, delta2, scale1, scale2 }), a1);
        //     let res2 = subtract(computeAxis2({ azi, alt, omega, angle, delta1, delta2, scale1, scale2 }), a2);
        //     return res1.concat(res2);
        // }

        // const fp = ([azi, alt, omega, angle, delta1, delta2, scale1, scale2]) => {
        //     return transpose(matrix([
        //         divide(subtract(f([azi + 0.01, alt, omega, angle, delta1, delta2, scale1, scale2]), f([azi - 0.01, alt, omega, angle, delta1, delta2, scale1, scale2])), 0.02),
        //         divide(subtract(f([azi, alt + 0.01, omega, angle, delta1, delta2, scale1, scale2]), f([azi, alt - 0.01, omega, angle, delta1, delta2, scale1, scale2])), 0.02),
        //         divide(subtract(f([azi, alt, omega + 0.01, angle, delta1, delta2, scale1, scale2]), f([azi, alt, omega - 0.01, angle, delta1, delta2, scale1, scale2])), 0.02),
        //         divide(subtract(f([azi, alt, omega, angle + 0.01, delta1, delta2, scale1, scale2]), f([azi, alt, omega, angle - 0.01, delta1, delta2, scale1, scale2])), 0.02),
        //         divide(subtract(f([azi, alt, omega, angle, delta1 + 0.01, delta2, scale1, scale2]), f([azi, alt, omega, angle, delta1 - 0.01, delta2, scale1, scale2])), 0.02),
        //         divide(subtract(f([azi, alt, omega, angle, delta1, delta2 + 0.01, scale1, scale2]), f([azi, alt, omega, angle, delta1, delta2 - 0.01, scale1, scale2])), 0.02),
        //         divide(subtract(f([azi, alt, omega, angle, delta1, delta2, scale1 + 0.01, scale2]), f([azi, alt, omega, angle, delta1, delta2, scale1 - 0.01, scale2])), 0.02),
        //         divide(subtract(f([azi, alt, omega, angle, delta1, delta2, scale1, scale2 + 0.01]), f([azi, alt, omega, angle, delta1, delta2, scale1, scale2 - 0.01])), 0.02),
        //     ]))
        // }

        // const normalize = ([azi, alt, omega, angle, delta1, delta2, scale1, scale2]) => {
        //     azi = (azi + 360) % 360;
        //     alt = (alt + 90) % 180 - 90;
        //     omega = (omega + 360) % 180;
        //     angle = (angle + 360) % 180;
        //     delta1 = (delta1 + 360) % 180;
        //     delta2 = (delta2 + 360) % 180;
        //     scale1 = Math.abs(scale1);
        //     scale2 = Math.abs(scale2);
        //     return [azi, alt, omega, angle, delta1, delta2, scale1, scale2]
        // }

        // const netwton = (angles) => {
        //     for (let iter = 0; iter < 64; iter++) {
        //         let fval = f(angles);
        //         let jac = fp(angles);
        //         console.log(angles, fval, jac)
        //         angles = subtract(angles, multiply(inv(jac), fval)).toArray()
        //         angles = normalize(angles);
        //         if (norm(fval) < 1e-3) {
        //             break;
        //         }
        //     }
        //     return angles
        // }

        // try {
        //     let [azi, alt, omega, angle, delta1, delta2, scale1, scale2] = netwton([1, 2, 3, 90, 4, 5, 4, 4]);
        //     setAzi(round(azi, 1));
        //     setAlt(round(alt, 1));
        //     setOmega(round(omega, 1));
        //     setAngle(round(angle, 1));
        //     setDelta1(round(delta1, 1));
        //     setDelta2(round(delta2, 1));
        //     setScale1(round(scale1, 2));
        //     setScale2(round(scale2, 2));
        // } catch (err) {
        //     console.error(err);
        // }
    }

    useEffect(() => {
        if (!selfUpdate) {
            updateAngles();
        }
        setSelfUpdate(false);
    },
        [projectionParameters]
    )

    const handleAziChange = (e) => {
        let value = Number(e.target.value);
        setAzi(value);
        console.log('Azi change', value)
        updateProjectionParameters({ azi: value, alt, omega, angle, delta1, delta2, scale1, scale2 });
    }

    const handleAltChange = (e) => {
        let value = Number(e.target.value);
        setAlt(value);
        updateProjectionParameters({ azi, alt: value, omega, angle, delta1, delta2, scale1, scale2 });
    }

    const handleOmegaChange = (e) => {
        let value = Number(e.target.value);
        setOmega(value);
        updateProjectionParameters({ azi, alt, omega: value, angle, delta1, delta2, scale1, scale2 });
    }

    const handleAngleChange = (e) => {
        let value = Number(e.target.value);
        setAngle(value);
        updateProjectionParameters({ azi, alt, omega, angle: value, delta1, delta2, scale1, scale2 });
    }

    const handleScale1Change = (e) => {
        let value = Number(e.target.value);
        setScale1(value);
        updateProjectionParameters({ azi, alt, omega, angle, delta1, delta2, scale1: value, scale2 });
    }

    const handleScale2Change = (e) => {
        let value = Number(e.target.value);
        setScale2(value);
        updateProjectionParameters({ azi, alt, omega, angle, delta1, delta2, scale1, scale2: value });
    }

    const handleDelta1Change = (e) => {
        let value = Number(e.target.value);
        setDelta1(value);
        updateProjectionParameters({ azi, alt, omega, angle, delta1: value, delta2, scale1, scale2 });
    }

    const handleDelta2Change = (e) => {
        let value = Number(e.target.value);
        setDelta2(value);
        updateProjectionParameters({ azi, alt, omega, angle, delta1, delta2: value, scale1, scale2 });
    }

    return (
        <div className="projectionSliders">
            <LabeledSlider label="Azimuth" min={0} max={360} value={azi} onChange={handleAziChange} />
            <LabeledSlider label="Altitude" min={-90} max={90} value={alt} onChange={handleAltChange} />
            <LabeledSlider label="Omega" min={-90} max={90} value={omega} onChange={handleOmegaChange} />
            <LabeledSlider label="Delta 1" min={0} max={360} value={delta1} onChange={handleDelta1Change} />
            <LabeledSlider label="Delta 2" min={-90} max={90} value={delta2} onChange={handleDelta2Change} />
            <LabeledSlider label="Angle" min={0} max={180} value={angle} onChange={handleAngleChange} />
            <LabeledSlider label="Scale 1" min={0} max={50} absMax={200} stepSlider={0.1} value={scale1} onChange={handleScale1Change} />
            <LabeledSlider label="Scale 2" min={0} max={50} absMax={200} stepSlider={0.1} value={scale2} onChange={handleScale2Change} />
        </div>
    );
}

export default ProjectionSliders;