import './Quest.css';
import tractor from './tractor.png';
import { MathJaxContext, MathJax } from 'better-react-mathjax';

const Math = (props) => {
  const mathJaxConfig = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [["$", "$"]],
      displayMath: [["$$", "$$"]]
    }
  };

  return (
    <span className="equation">
      <MathJaxContext config={mathJaxConfig} version={3}>
        <MathJax inline>
          {props.expr}
        </MathJax>
      </MathJaxContext>
    </span>
  );
}



const Quest = () => {

  return (
    <div className="quest">
      <div className="textblock">

        <div className="img-container">
          <img src={tractor} />
        </div>
        <p>
          The two treasure hunters Lorenz and Euler are on a quest to retrieve the long lost Heart of the Strange Tractor, the most valuable gemstone in existence.
          After years of research, they finally located it at the bottom of the ocean and plan to recover it using a small submarine.
        </p>
        <p>
          They start their expedition at sea level at the coordinates (2, -1).
          While Euler operates the vessel, Lorenz remains in the control room and computes corrections for its direction and speed that are forwarded every three hours.
        </p>
        <p>
          Euler reaches the sunken Strange Tractor after 9 hours. Unfortunately, he lost communication and doesn't know at which depth he is and whether it is safe to exit the submarine. He only remembers that he was at a depth of 44838 units the last time he heard from Lorenz.
        </p>
        <p>
          Do you know how deep Euler is?
        </p>
        <p>
          Tweet your answers at <a href="https://twitter.com/strngeattrctors" target="_blank">@StrngeAttrctors</a>. The first to provide the correct value wins a secure minting slot.
        </p>

        <h2>Solution</h2>

        <p>
          The key information for solving the problem can be found in the names of our two treasure hunters.
        </p>
          <p>
          Let's start with Lorenz.
          As the name suggested, the updates computed by him follow the Lorenz set of differential equations
          <p className="equation-container">
            <Math expr={String.raw`$\dot{x} = \sigma (y  - x)$`} />
            <Math expr={String.raw`$\dot{y} =  x (\rho - z) - y$`} />
            <Math expr={String.raw`$\dot{z} = xy - \beta z$`} />
          </p>
          with the standard parameters  <Math expr={String.raw`$\rho = 28$`} />, <Math expr={String.raw`$\sigma = 10$`} /> and <Math expr={String.raw`$\beta = 8/3$`} />.
          Don't get scared, these equations look in general more complicated than they are.
          Actually, they can be understood quite intuitively.
          Let me explain.
        </p>

        <p>
          On the left-hand side, you can see the coordinates with a little dot.
          Dots usually express rates of change or, more simply put, velocities.
          So, intuitively speaking, the Lorenz equations give you a velocity for any given point in space and tell you how it will move.
          These are the velocities that Lorenz communicated to Euler in our little story.
        </p>

        <p>
          Euler being the operator of the submarine hinted that Euler's method has to be used to solve the problem.
          Let me again make a small math detour first, before coming back to our story.
          In general, differential equations like the Lorenz one can be written in the following form
          <p className="equation-container">
            <Math expr={String.raw`$\dot{u} = f(u)$`} />
          </p>
          where  <Math expr={String.raw`$u = (x,y,z)$`} /> corresponds to our dynamic variables of interest (our point in space) and  <Math expr={String.raw`$f(u)$`} /> to a function that gives you its velocities.
          A very intuitive idea to simulate the evolution of our point would be to move with said velocity for a given amount of time <Math expr={String.raw`$\Delta t$`} />.
          So if we start at point <Math expr={String.raw`$u_0$`} /> we would end up at
          <p className="equation-container">
            <Math expr={String.raw`$u_1 = u_0 + \Delta t \cdot f(u_0)$`} />
          </p>
          and get to a new position in space for which we can repeat the procedure.
          This simple rule for updating the state of a system by following its velocity (or rate of change) is also known as Euler's method.
        </p>

        <p>
          Putting everything together, this means that Euler sequentially follows the velocities provided by Lorenz for <Math expr={String.raw`$\Delta t = 3$`} />.
          Starting at <Math expr={String.raw`$(x,y) = (2, -1)$`} /> and <Math expr={String.raw`$z = 0$`} /> (corresponding to sea level), Euler reaches the following sequence of locations
          <div className="table-container">
            <table className="table">
              <tr>
                <th></th>
                <th>x</th>
                <th>y</th>
                <th>z</th>
              </tr>
              <tr>
                <td> <Math expr={String.raw`$u_0$`} /></td>
                <td>2</td>
                <td>-1</td>
                <td>0</td>
              </tr>
              <tr>
                <td> <Math expr={String.raw`$u_1$`} /></td>
                <td>-88</td>
                <td>170</td>
                <td>-6</td>
              </tr>
              <tr>
                <td> <Math expr={String.raw`$u_2$`} /></td>
                <td>7652</td>
                <td>-9316</td>
                <td>-44838</td>
              </tr>
              <tr>
                <td> <Math expr={String.raw`$u_3$`} /></td>
                <td>-501388</td>
                <td>1029962528</td>
                <td>-213544230</td>
              </tr>
            </table>
          </div>
        </p>
        <p>
          Therefore, the correct answer to the puzzle is z = -213544230.
        </p>




      </div>
    </div >
  );
}

export default Quest;
