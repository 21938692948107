import "./Contest.css";
import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as utils from "../../components/MyTools/utils.js";
import * as utils2 from "../../components/MyTools/utils2.js";
import participants from "./participants.json";
import { SvgWindow } from "../../components/SvgWindow";

function shuffle1(arr: any[]): any[] {
  return Array(arr.length)
    .fill(null)
    .map((_, i) => [Math.random(), i])
    .sort(([a], [b]) => a - b)
    .map(([, i]) => arr[i]);
}

const Entry = ({ id, creator }) => {
  const [svg, setSvg] = useState(
    "%3Csvg width='1024' height='1024' viewBox='-4096 -4096 8192 8192' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='-4096' y='-4096' width='100%25' height='100%25' fill='black'/%3E%3C/svg%3E"
  );

  const [annotation, setAnnotation] = useState(undefined);

  const [rendering, setRendering] = useState(false);
  const [afterSvg, setAfterSvg] = useState();
  const [beforeSvg, setBeforeSvg] = useState();

  const [currentImg, setCurrentImg] = useState("after");

  useEffect(async () => {
    setRendering(true);
    let svg = await utils.renderTokenId(id);
    setAfterSvg(svg);
    setSvg(svg);
    setRendering(false);
  }, [id]);

  const toggleImage = async () => {
    if (currentImg == "after") {
      setCurrentImg("before");
      let _beforeSvg;
      if (beforeSvg == undefined) {
        setRendering(true);
        _beforeSvg = await utils.renderTokenIdDefault(id);
        setBeforeSvg(_beforeSvg);
        setRendering(false);
      } else {
        _beforeSvg = beforeSvg;
      }
      setSvg(_beforeSvg);
      setAnnotation("Before");
    } else {
      setCurrentImg("after");
      setAnnotation(undefined);
      setSvg(afterSvg);
    }
  };

  return (
    <div className="entry" id={id}>
      <SvgWindow
        data={svg}
        loading={rendering}
        onClick={toggleImage}
        annotation={annotation}
      />
      <div className="entry-info">
        <div className="entry-name">{utils2.getTokenNameHash(id)}</div>
        <div className="entry-creator">
          by <a href={"https://twitter.com/" + creator}>@{creator}</a>
        </div>
      </div>
    </div>
  );
};

const Contest = () => {
  let { size } = useParams();

  const [entrySize, setEntrySize] = useState(
    size ? `${size}px` : window.innerWidth < 400 ? "100%" : "400px"
  );

  const SelectSizeButton = ({ title, size }) => {
    return (
      <span className={entrySize == size ? "invert" : ""}>
        <input
          type="button"
          onClick={() => setEntrySize(size)}
          value={title}
          className="button"
        />
      </span>
    );
  };

  const entries = useMemo(() => shuffle1(participants), []);

  return (
    <div className="contest">
      <Helmet>
        <title>Contest | Strange Attractors</title>
        <meta
          name="description"
          content="Strange Attractors is an interactive, on-chain, generative art, NFT project that simulates three-dimensional, chaotic systems using nothing but an ethereum smart contract."
        />
      </Helmet>

      <h2>Design Contest</h2>

      <div>
        Submissions for the on-chain community design contest. For more info
        check{" "}
        <a href="https://twitter.com/StrngeAttrctors/status/1464552393849384961">
          [this post]
        </a>
        .<br />
        Click the images to view the Strange Attractors before customization.
      </div>

      <div className="size-button-box">
        <SelectSizeButton title="small" size="200px" />
        <SelectSizeButton title="medium" size="400px" />
        <SelectSizeButton title="large" size="500px" />
        <SelectSizeButton title="x-large" size="100%" />
      </div>

      <div
        className="gallery"
        style={{
          gridTemplateColumns:
            "repeat(auto-fill, minmax(" + entrySize + ", 1fr))",
        }}
      >
        {entries.map((participant, idx) => (
          <Entry key={idx} {...participant} />
        ))}
      </div>
    </div>
  );
};

export default Contest;
