import React, { useState } from 'react';

export const Explanation = ({foldedOut}) => {
    // const [foldedOut, setFoldedOut] = useState(false);

    return (
        <div>
            {/* <div>
                <input type="button" onClick={() => setFoldedOut(!foldedOut)} value="Help" />
            </div> */}

            {foldedOut &&
                <div className="explanation-text">
                    <ul>
                        <li><strong>Select</strong> the token that you want to customize.<br />
                            If you don't hold any tokens you might want to check out the <a href="demo">demo version</a></li>
                        <li><strong>Reload</strong>: Reloads the parameters of the selected token from the blockchain.</li>
                        <li><strong>Render</strong>: Renders the selected token with the current parameters.</li>
                        <li><strong>Download SVG/PNG</strong>: Saves the current rendering to your local machine.</li>
                        <li><strong>Export settings</strong>: Saves the current settings as a file to your local machine.</li>
                        <li><strong>Import settings</strong>: Imports previously saved settings.</li>
                        <li><strong>Commit XYZ</strong>: Writes the current settings to the blockchain (incurs gas fees).</li>
                        <li><strong>Reset Defaults</strong>: Opens a tab where you can choose which aspects should be reset to their defaults after mint. Afterwards, click "Do Reset" to perform the reset (incurs gas fees).</li>
                        <li><strong>Projection</strong>: Move the sliders or set values manually to change the projection of your Strange Attractor.
                            More details on what the parameters mean can be found in <a href="details">Technical Details</a></li>
                        <li><strong>Size</strong>: Change the stroke width or point size in the rendering.</li>
                        <li><strong>Colors</strong>: Define the color grading of your Strange Attractor.
                            Add/remove color anchors using the "+" and "x" buttons. Change the color by clicking on it and change its location using the slider.</li>
                    </ul>
                </div>
            }
        </div>
    );
}
