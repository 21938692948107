import React, { useState } from 'react';

import { fetchTokenList } from './utils.js'



export const TokenSelector = ({ account, onChange, ...rest }) => {
    const [tokenList, setTokenList] = React.useState([]);
    const [selected, setSelected] = React.useState("none");
    const [loaded, setLoaded] = React.useState(false)

    React.useEffect(async () => {
        if (account != "") {
            let tokenList = await fetchTokenList(account)
            setTokenList(tokenList);
            setLoaded(true);
        }
    }, [account]);

    const handleChange = (e) => {
        setSelected(e.target.value);
        onChange(e);
    }

    return (
        <div {...rest}>
            <label>Token: </label>
            <select value={selected} onChange={handleChange} disabled={!loaded}>
                <option disabled value="none" style={{ textAlign: 'center' }}> -- select a token -- </option>
                {tokenList != undefined && tokenList.map((pair) => <option key={pair.value} value={pair.value}>{pair.label}</option>)}
            </select>
        </div>
    );
}
