const ArrayInputFields = ({ values, setValues, label }) => {

    const changeHandlerGenerator = (idx) => {
        return (e) => {
            values[idx] = Number(e.target.value);
            setValues(values);
            console.log('array update')
        }
    }

    return (
        <div>
            <div className="array-input-label">{label}</div>
            {values.map((value, idx) => {
                return <input type="number" value={value} key={idx} step='0.01'
                    onChange={changeHandlerGenerator(idx)}
                />
            })}
        </div>
    );
}



export const ProjectionExpert = ({ projectionParameters, setProjectionParameters }) => {

    const setField = (fieldName) => (
        (arr) =>
            setProjectionParameters({ ...projectionParameters, [fieldName]: arr })

    );

    return (
        <div className="projectionExpert">
            <div>
                <div className="array-input-label"></div>
                <div className="array-column-label">x</div>
                <div className="array-column-label">y</div>
                <div className="array-column-label">z</div>
                {projectionParameters.axis1.length == 4 &&
                    <div className="array-column-label">w</div>
                }
            </div>
            <ArrayInputFields values={projectionParameters.axis1} setValues={setField("axis1")} label="Axis 1" />
            <ArrayInputFields values={projectionParameters.axis2} setValues={setField("axis2")} label="Axis 2" />
            <ArrayInputFields values={projectionParameters.offset} setValues={setField("offset")} label="Offset" />
        </div>
    );
}


export default ProjectionExpert;