import "./Footer.css";

import React from "react";
import { Link, withRouter } from "react-router-dom";

const Footer = (props) => {
  return (
    <div className="footer">
      <span>
        By <a href="https://twitter.com/cxkoda">@cxkoda</a> / All rights
        reserved / <a href="/impressum">Impressum</a>
      </span>
    </div>
  );
};

export default Footer;
