import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers'

export const ConnectButton = ({ account, setAccount, setChainId = ((chainId) => { }) }) => {

  const handleAccountsChanged = ((accounts) => {
    console.log('account changed');
    if (accounts.length === 0) {
      setAccount('')
      console.log('Please connect using MetaMask.');
    } else if (accounts[0] !== account) {
      setAccount(accounts[0])
    }
  });

  const connect = () => {
    if (typeof window.ethereum !== 'undefined') {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(handleAccountsChanged)
        .catch((err) => {
          if (err.code === 4001) {
            // EIP-1193 userRejectedRequest error
            // If this happens, the user rejected the connection request.
            console.log('Please connect to MetaMask.');
          } else {
            console.error(err);
          }
        });
    } else {
      alert("Unable to detect metamask.");
    }
  }

  // Add Metamask boilerplate
  const addMetamaskListeners = () => {
    // /**********************************************************/
    // /* Handle chain (network) and chainChanged (per EIP-1193) */
    // /**********************************************************/

    window.ethereum.on('chainChanged', (chainId) => {
      window.location.reload();
      setChainId(chainId);
    });

    /***********************************************************/
    /* Handle user accounts and accountsChanged (per EIP-1193) */
    /***********************************************************/
    window.ethereum.on('accountsChanged', handleAccountsChanged);

    window.ethereum
      .request({ method: 'eth_accounts' })
      .then((accounts) => 
      {
        if (account === "")
        handleAccountsChanged(accounts)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const resetChainId = (async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    let net = await provider.getNetwork();
    setChainId(net.chainId);
  });

  if (typeof window.ethereum !== 'undefined') {
    useEffect(resetChainId, [account]);
    useEffect(addMetamaskListeners, []);
  }

  return (<div className="wallet-connect">
    <span>Wallet: </span>
    {account === "" &&
      <input type="button" onClick={connect} value="Connect" className="button"></input>
    }
    {account !== "" && <span>0x{account.slice(2).toUpperCase()}</span>}

  </div>);
}



export default ConnectButton;