import React, { useState } from "react";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div className="about">
      <Helmet>
        <title>Impressum</title>
        <meta name="description" content="Impressum" />
      </Helmet>

      <div className="textblock">
        <h4>Impressum</h4>
        <p>
          <b>
            Informationen und Offenlegung gemäß &sect; 25 MedienG, &sect;5 (1)
            ECG &sect; 63 GewO und &sect; 14 UGB
          </b>
        </p>{" "}
        <p>
          <b>Betreiber der Webseite:</b> David Huber
        </p>
        <p>
          <b>Anschrift:</b> Höttinger Auffahrt 1, 6020, Innsbruck, Österreich
        </p>
        <p>
          <b>Umsatzsteueridentifikationsnummer:</b> ATU78011367 <br />{" "}
          <b>Gewerbeaufsichtbehörde:</b> Magistrat der Stadt Innsbruck <br />{" "}
          <b>Mitgliedschaften:</b>
        </p>
        <p>
          <b>Kontaktdaten:</b> <br /> Telefon: +436606285818 <br /> Email:
          me@david-huber.eu <br /> Fax:{" "}
        </p>
        <p>
          <b>Anwendbare Rechtsvorschrift:</b> www.ris.bka.gv.at <br />{" "}
          <b>Berufsbezeichnung:</b> Softwareentwickler
        </p>
        <p>
          <b>Online Streitbeilegung:</b> Verbraucher mit einer Niederlassung in
          Österreich oder einem sonstigen Vertragsstaat der ODR-VO, haben die
          Möglichkeit bei Problemen hinsichtlich des entgeltlichen Kaufs von
          Waren oder Dienstleistungen die Online-Streitbelegung der Europäischen
          Kommission über folgende Plattform in Anspruch zu nehmen
          https://ec.europa.eu/consumers/odr
        </p>
        <p>
          <b>Urheberrecht:</b> Die auf dieser Webseite zur Verfügung gestellten
          Inhalte unterliegen, soweit dies rechtlich möglich ist, diversen
          Schutzrechten (z.B dem Urheberrecht). Eine Verwendung oder Verbreitung
          von bereitgestelltem Material, bedarf der schriftlichen Zustimmung des
          Webseitenbetreibers.
        </p>
        <p>
          <b>Haftungsausschluss:</b> Der Betreiber dieser Webseite hat alle
          Inhalte sowie ausgehende Links mit größtmöglichem Sorgfaltsmaßstab
          kontrolliert und ausgewählt. Trotzdem wird keine Haftung für den
          Inhalt externer Webseiten übernommen. Für diesen ist ausschließlich
          deren jeweiliger Betreiber verantwortlich. Für den Fall, dass Sie auf
          ausgehende Links aufmerksam werden, welche auf eine Seite mit
          rechtswidriger Tätigkeit oder rechtswidrigem Inhalt verweisen,
          ersuchen wir um entsprechenden Hinweis an die obig angeführte
          Email-Adresse, um betroffene Inhalt umgehend nach § 17 (2) ECG zu
          entfernen. <br /> Die Urheberrechte Dritter werden vom Betreiber
          dieser Webseite mit größter Sorgfalt beachtet. Sollten Sie auf eine
          Urheberrechtsverletzung aufmerksam werden, ersuchen wir auch um einen
          entsprechenden Hinweis. Bei Bekanntwerden derartiger
          Rechtsverletzungen wird der Webseitenbetreiber den betroffenen Inhalt
          umgehend entfernen.
        </p>
        <p>
          Quelle:{" "}
          <b>
            <a href="https://www.rechtstexte-generator.at/impressum-generator-oesterreich/">
              Impressum-Generator von rechtstexte-generator.at
            </a>
          </b>{" "}
          in Kooperation mit{" "}
          <a href="http://deinekrankenversicherung.at/">
            Private Krankenversicherung Österreich
          </a>
        </p>
      </div>
    </div>
  );
};

export default About;
