import "./Mint.css";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import * as utils from "../../components/MyTools/utils.js";
import * as utils2 from "../../components/MyTools/utils2.js";
import { ConnectButton } from "../../components/MyTools/ConnectButton";

// import { TokenSelector } from '../../components/MyTools/TokenSelector';

export const fetchNonUsedTokensOfSystem = async (account, systemId) => {
  try {
    const contract = utils.getContract();

    let numberOfTokens = await contract.balanceOf(account);

    let tokens = [];
    const fetchNonUsedTokenOfSystem = async (idx) => {
      let tokenId = await contract.tokenOfOwnerByIndex(account, idx);
      let _systemId = utils2.getTokenSystemId(tokenId);
      if (_systemId === systemId) {
        let token = await contract.tokens(tokenId);
        if (!token.usedForFullsetToken) {
          let tokenName = await contract.getTokenName(tokenId);
          tokens.push({ label: unescape(tokenName), value: tokenId });
        }
      }
    };

    const txs = [];
    for (let idx = 0; idx < numberOfTokens; ++idx) {
      txs.push(fetchNonUsedTokenOfSystem(idx));
    }
    await Promise.all(txs);

    tokens = tokens.sort((left, right) => left.value - right.value);

    console.info("Nonused tokens fetched:", tokens);

    return tokens;
  } catch (error) {
    console.error(error);
  }
};

export const TokenSelector = ({ account, onChange, systemId, enabled }) => {
  const [tokenList, setTokenList] = React.useState([]);
  const [selected, setSelected] = React.useState("none");

  React.useEffect(async () => {
    setTokenList(await fetchNonUsedTokensOfSystem(account, systemId));
  }, [account]);

  const handleChange = (e) => {
    setSelected(e.target.value);
    onChange(e);
  };

  const labels = ["", "Lorenz", "Dadras-Momeni", "Halvorsen", "Coullet"];

  return (
    <div className="token-selector-container">
      <label className="token-selector-label">{labels[systemId]}:</label>
      <select
        disabled={!enabled}
        className="token-selector"
        name="tokens"
        value={selected}
        onChange={handleChange}
      >
        <option disabled value="none" style={{ textAlign: "center" }}>
          select token
        </option>
        {tokenList != undefined &&
          tokenList.map((pair) => (
            <option key={pair.value} value={pair.value}>
              {pair.label}
            </option>
          ))}
      </select>
    </div>
  );
};

const FullsetMint = ({ account }) => {
  const [fullsetMintEnabled, setFullsetMintEnabled] = useState(false);

  const [tokenSacrifice1, setTokenSacrifice1] = useState(0);
  const [tokenSacrifice2, setTokenSacrifice2] = useState(0);
  const [tokenSacrifice3, setTokenSacrifice3] = useState(0);
  const [tokenSacrifice4, setTokenSacrifice4] = useState(0);

  const getSlotData = async () => {
    try {
      const contract = utils.getContract();
      let enabled = await contract.isFullsetMintEnabled();
      setFullsetMintEnabled(enabled);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(getSlotData, [account]);

  const doFullsetMint = () => {
    utils.mintFullset([
      tokenSacrifice1,
      tokenSacrifice2,
      tokenSacrifice3,
      tokenSacrifice4,
    ]);
  };

  const canMint = () => {
    return (
      fullsetMintEnabled &&
      tokenSacrifice1 > 0 &&
      tokenSacrifice2 > 0 &&
      tokenSacrifice3 > 0 &&
      tokenSacrifice4 > 0
    );
  };

  return (
    <div className="mint-container">
      {!fullsetMintEnabled && <h4>Completing has not been enabled yet!</h4>}

      <div className="token-selectors">
        <TokenSelector
          onChange={(e) => setTokenSacrifice1(e.target.value)}
          account={account}
          systemId={1}
          enabled={fullsetMintEnabled}
        />
        <TokenSelector
          onChange={(e) => setTokenSacrifice2(e.target.value)}
          account={account}
          systemId={2}
          enabled={fullsetMintEnabled}
        />
        <TokenSelector
          onChange={(e) => setTokenSacrifice3(e.target.value)}
          account={account}
          systemId={3}
          enabled={fullsetMintEnabled}
        />
        <TokenSelector
          onChange={(e) => setTokenSacrifice4(e.target.value)}
          account={account}
          systemId={4}
          enabled={fullsetMintEnabled}
        />
      </div>

      <div className="mint-button-container">
        <input
          type="button"
          value="Complete Collection"
          className="mint-button"
          onClick={doFullsetMint}
          disabled={!canMint()}
        ></input>
      </div>
    </div>
  );
};

const Mint = () => {
  const [account, setAccount] = useState("");

  return (
    <div className="fullmint">
      <Helmet>
        <title>Collection Completion | Strange Attractors</title>
        <meta
          name="description"
          content="Complete your Strange Attractor collection here by claiming the Huang attractor! Strange Attractors is an interactive, on-chain, generative art, NFT project that simulates three-dimensional, chaotic systems using nothing but an ethereum smart contract."
        />
      </Helmet>

      <div className="textblock">
        <div className="description">
          <h2>Complete your collection</h2>
          <p>
            There is a hidden 0th system in the collection -- the{" "}
            <strong>Huang attractor</strong>.
          </p>
          <p>
            It's a very special system since it is the only four-dimensional one
            in the collection. Tokens could not be obtained directly during mint
            Instead, they can only be claimed by collectors holding a full set
            of Strange Attractors (one of every regular system). In doing so,
            their pieces will be marked as <strong>completed</strong> and cannot
            be used a second time to claim.
          </p>
          <p>
            Connect your wallet and select the Strange Attractors you want to
            use to complete your collection and claim your Huang attractor.
          </p>
        </div>
      </div>
      <div className="main-interactive-container">
        <ConnectButton {...{ account, setAccount }} />
        {account != "" && <FullsetMint {...{ account }} />}
      </div>
    </div>
  );
};

export default Mint;
